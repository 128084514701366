<template>
    <div class="saas_detail_body">
        <div id="home" class="saas_detail_body_top_info">
            <div class="saas_detail_body_top_left_info">
                <div class="saas_detail_body_top_left_info_one">
                    <div class="saas_detail_body_top_left_info_title_view">
                        <div class="saas_detail_body_top_left_info_title_view_lab">{{product.spName}}</div>
                        <div class="saas_detail_body_top_left_info_title_view_exp"
                             v-if="product.typeValue == 2 && product.spCode !='resource_bank' "
                        >
                            <!-- 依赖 • {{product.relyName}}-->
                            由第三方提供
                        </div>
                    </div>
                    <div class="saas_detail_body_top_left_info_remark" v-if="product.spRemark != null">
                        <div class="saas_detail_body_top_left_info_remark_one">{{product.spRemark}}
                            <span v-show="relyList.length > 0">（ 需同时订阅
                                <span v-for="(itemT,indexT) in relyList" :key="indexT">
                                    <span @click="goSup({id: itemT.id, code:itemT.code})"
                                          class="product_info_rely"
                                          style="color:#589CFD;"> {{ itemT.name }}</span> <span
                                        v-show="relyList.length > 1 && indexT < relyList.length-1 ">或</span> </span>）
                            </span>
                            <span v-show="product.relyName != null &&  relyList.length ==0 ">（ 需同时订阅
                                <span>
                                    <span @click="goSup({id: product.id, code:product.spCode})"
                                          class="product_info_rely"
                                          style="color:#589CFD;"> {{ product.relyName }}</span>  </span>）
                            </span>
                        </div>
                    </div>
                    <div class="saas_detail_body_top_left_info_price" v-show="product.statusValue == 10">
                        订阅价格
                        <div class="saas_detail_body_price_color">{{product.unitPrice}}</div>
                        {{product.unitName}}
                    </div>
                    <div class="saas_detail_body_sup_product_view"
                         v-if="supProList.length > 0 && product.statusValue == 10 ">
                        <div class="saas_detail_body_sup_product_view_title">推荐搭配 ></div>
                        <div class="saas_detail_body_sup_product_view_list">
                            <div class="saas_detail_body_product_tab_item" v-for="(item, index) in supProList"
                                 @click="goSup(item)"
                                 :key="index">{{item.name}}
                            </div>
                        </div>
                    </div>
                    <div class="saas_detail_body_top_left_info_btnview">
                        <!-- 新产品订阅 -->
                        <div class="saas_detail_body_top_left_info_btnview_button"
                             :class="{'qr_code_button_top':!showProductQrCode}"
                             @mouseover="mouseOver" @mouseleave="mouseLeave"
                             v-if="product.statusValue == 10">
                            <el-button-group v-if="experience">
                                <el-button class="goto_try" round @click="gotoTryAction()"> 申请试用</el-button>
                                <el-button class="goto_subscribe" type="primary" round @click="gotoSubscribeAction()">
                                    前往订阅
                                </el-button>
                            </el-button-group>
                            <el-button v-else style="width: 133px; height: 40px;font-size: 22px;font-family: Source Han Sans SC;
                            font-weight: 400;background-color: #0050DA;
                            color: #FFFFFF; padding-bottom: 7px" type="primary" round @click="gotoSubscribeAction()">
                                前往订阅
                            </el-button>

                            <div class="develop_button_title " v-show="showProductQrCode">请与产品负责人联系 ></div>
                            <div class="develop_button_qr_code_min " v-show="showProductQrCode">
                                <img :src="qr_code.img" width="25" height="25"/>
                            </div>
                            <div class="develop_button_qr_code_max " v-show="showProductQrCode"
                                 :class="{'develop_button_qr_code_max_mouseover':!showQrCode,'develop_button_qr_code_max_mouseleave':showQrCode}">
                                <img :src="qr_code.qr_img" width="100" height="100"/>
                            </div>

                        </div>
                        <div class="saas_detail_body_top_left_info_btnview_button" @mouseover="mouseOver"
                             :class="{'qr_code_button_top':!showProductQrCode}"
                             @mouseleave="mouseLeave" v-else>
                            <el-button class="develop_button" type="info"> {{product.statusName}}</el-button>
                            <div class="develop_button_title " v-show="showProductQrCode">请与产品负责人联系 ></div>
                            <div class="develop_button_qr_code_min " v-show="showProductQrCode">
                                <img :src="qr_code.img" width="25" height="25"/>
                            </div>
                            <div class="develop_button_qr_code_max " v-show="showProductQrCode"
                                 :class="{'develop_button_qr_code_max_mouseover':!showQrCode,'develop_button_qr_code_max_mouseleave':showQrCode}">
                                <img :src="qr_code.qr_img" width="100" height="100"/>
                            </div>
                        </div>
                        <!-- 已订阅的直接查看详情 -->
                    </div>
                </div>
            </div>
            <div class="saas_detail_body_top_right_img">
                <img style="width:100%" src="@/assets/hrsaas/detail/hr_banner_saas.png"/>
            </div>
            <div class="saas_detail_body_discount_body">
                <template v-for="(item, index) in discountList">
                    <div class="saas_detail_body_discount_body_item" :key="index" @click="goActive(index)">
                        <div class="saas_detail_body_discount_body_item_top">
                            <div class="saas_detail_body_discount_body_item_top_title">{{item.title}}</div>
                            <img v-if="item.img" style="width: 23px; height: 29px" :src="item.img"/>
                        </div>
                        <div class="saas_detail_body_discount_body_item_bottom">{{item.msg}}</div>
                    </div>
                </template>
            </div>
        </div>
        <div id="saas_detail_body_product"
             :class="{'product_features_is_null': isFunctionData,'product_features_is_not_null': !isFunctionData}"
             class="saas_detail_body_product">
            <div class="saas_detail_body_product_tab_title">
                <div class="saas_detail_body_product_tab_title_one"></div>
                <div class="saas_detail_body_product_tab_title_two"></div>
                <div class="saas_detail_body_product_tab_title_three">产品功能</div>
                <div class="saas_detail_body_product_tab_title_two"></div>
                <div class="saas_detail_body_product_tab_title_one"></div>
            </div>
            <div style="font-size: 30px;color: #b1abab;margin: 50px auto" v-if="isFunctionData">暂无描述</div>
            <div class="saas_detail_body_product_body" v-if="!isFunctionData">
                <div class="saas_detail_body_product_left_list">
                    <div v-for="(item, index) in localData.productFeatures"
                         :key="index" class="saas_detail_body_featur_item"
                         :class="{ 'saas_detail_body_featur_item_one': item.select,'saas_detail_body_featur_item_t':!item.select }"
                         @click="createSelFeature(item)">
                        {{ item.title }}
                    </div>
                </div>
                <div class="saas_detail_body_product_right">
                    <ul class="saas_detail_body_product_right_text">
                        <li class="saas_detail_body_product_right_content"
                            v-for="(item,index) in featurListOne.contentList"
                            :key="index">
                            <div class="saas_detail_body_product_right_point" style="display: inline"></div>
                            <div class="saas_detail_body_product_right_content_text">{{item.content}}</div>
                        </li>
                    </ul>
                    <div class="saas_detail_body_product_right_imgs" v-show="featurImgNull">
                        <el-carousel height="700px" :interval="5000" arrow="never" v-show="!featurImgs">
                            <el-carousel-item v-for="(item,index) in featurImgList" :key="index">
                                <img class="saas_detail_body_product_right_img" :src="item.img"/>
                            </el-carousel-item>
                        </el-carousel>
                        <el-carousel height="700px" :interval="5000" arrow="always" v-show="featurImgs">
                            <el-carousel-item v-for="(item,index) in featurImgList" :key="index">
                                <img class="saas_detail_body_product_right_img" :src="item.img"/>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>

        <div id="saas_detail_body_serPro"
             class="saas_detail_body_ser_pro"
             v-if="code == 'hr_service_company'  && serviceList&& serviceList.length>0">
            <div class="saas_detail_body_product_tab_title">
                <div class="saas_detail_body_product_tab_title_one"></div>
                <div class="saas_detail_body_product_tab_title_two"></div>
                <div class="saas_detail_body_product_tab_title_three">入驻服务商</div>
                <div class="saas_detail_body_product_tab_title_two"></div>
                <div class="saas_detail_body_product_tab_title_one"></div>
            </div>
            <div class="saas_detail_body_ser_pro_body">
  
                <template v-for="(item, index) in serviceList">
                    <div class="saas_detail_body_ser_pro_body_item" @click="goServiceProvideDetail(item) " :key="index">
                        <div class="saas_detail_body_ser_pro_body_item_detail">
                            <img class="saas_detail_body_ser_pro_body_item_detail_img" :src="item.recommend_file"/>
                            <div class="saas_detail_body_ser_pro_body_item_detail_title">
                                <div class="saas_detail_body_ser_pro_body_item_detail_title_left">{{ item.name }}</div>
                                <div v-if="item.type" class="saas_detail_body_ser_pro_body_item_detail_title_right">{{ item.type }}</div>
                            </div>
                            <div v-html="item.introduce" class="saas_detail_body_ser_pro_body_item_detail_remark">

                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div v-if="serviceList.length>0" class="saas_detail_body_ser_pro_footer">
                <div @click="loadMore" style="color:#1492FF;cursor: pointer;">查看更多</div>
                <div v-if="flag" @click="packUp" style="margin-left:10px;color:#1492FF;cursor: pointer;">收起</div>
            </div>
            
        </div>

        <div id="saas_detail_body_proSup" class="saas_detail_body_pro_sup">
            <div class="saas_detail_body_product_tab_title" style="width: auto">
                <div class="saas_detail_body_product_tab_title_one"></div>
                <div class="saas_detail_body_product_tab_title_two"></div>
                <div class="saas_detail_body_product_tab_title_three">产品优势</div>
                <div class="saas_detail_body_product_tab_title_two"></div>
                <div class="saas_detail_body_product_tab_title_one"></div>
            </div>
            <div class="saas_detail_body_pro_sup_banner" v-if="isAdvantageData ">
                <div class="saas_detail_body_pro_sup_banner_zero">
                    <div class="saas_detail_body_pro_sup_banner_one" v-for="(item,index) in localData.params"
                         :key="index">
                        <div class="saas_detail_body_pro_sup_banner_img">
                            <img height="60px" width="60px" :src="item.img"/>
                        </div>
                        <div class="saas_detail_body_pro_sup_banner_two">{{item.title}}</div>
                        <div class="saas_detail_body_pro_sup_banner_three">{{item.msg}}</div>
                    </div>
                </div>
            </div>
            <div class="saas_detail_body_pro_sup_banner_null" v-if="!isAdvantageData">暂无描述</div>
        </div>
    </div>
</template>
<script>
    import aEntityAPI from "@/api/sysAPI.js";
    import {mapActions, mapGetters} from "vuex";
    import Vue from 'vue';
    import baseAPI from "@/project/zbrl/api/baseAPI.js";

    export default {

        props: ["saas"],

        components: {},

        computed: {
            ...mapGetters({
                sysConfig: "config/sysConfig"
            }),
            ...mapActions(["config/setConfig"])
        },

        data() {
            return {
                showQrCode: false,
                flag:false,
                pageNo:0,
                showProductQrCode: false,
                qr_code: {
                    img: require("@/assets/qr/qr_code.png"),
                    qr_img: null,
                    xiekangfeng: require("@/assets/qr/xiekangfeng.png"),
                    weiziling: require("@/assets/qr/weiziling.jpg"),
                    xufang: require("@/assets/qr/xufang.jpg"),
                    zhanglinlin: require("@/assets/qr/zhanglinlin.jpg"),
                    zhaoyufeng: require("@/assets/qr/zhaoyufeng.jpg"),
                },
                featurImgNull: true,
                relyList: [],
                isLogin: false,
                experience: false,
                curId: null,
                ceshi:require("@/assets/hrsaas/index/ceshi.png"),
                hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
                saasImg: require("@/assets/saas_logo.png"),
                code: '',
                spName: '',
                isFunctionData: false,
                isAdvantageData: false,
                title: '',
                mag: '',
                hostUrl: window.location.protocol + "//" + "localhost:8004",
                product: {},
                //推荐搭配数据
                supProList: [],
                serviceList:[],
                discountList: [
                    {
                        tag: 1,
                        title: "满年8折",
                        msg: "一次性订阅12月按8折收费",
                        img: "",
                    },

                    {
                        tag: 2,
                        title: "满减钜惠",
                        msg: "单次订单满2万按照8折计费",
                        img: "",
                    },
                    {
                        tag: 3,
                        title: "限时7折",
                        msg: "工具类型产品按照7折计费",
                        img: "",
                    },
                ],
                featurList:
                    [
                        {
                            "title": "运单管理",
                            "select": true,
                            "remark": "运单管理****************************************************************************",
                            "imgList": [{
                                "img": "/static/image/saasImg/space_img.png"
                            }, {
                                "img": "/static/image/banner1.png"
                            }, {
                                "img": "/static/image/saasImg/space_img.png"
                            }]
                        }, {
                        "title": "轨迹管理",
                        "select": false,
                        "remark": "轨迹管理****************************************************************************",
                        "imgList": [{
                            "img": "/static/image/saasImg/space_img.png"
                        }, {
                            "img": "/static/image/banner1.png"
                        }, {
                            "img": "/static/image/saasImg/space_img.png"
                        }]
                    }, {
                        "title": "全覆盖移动端",
                        "select": false,
                        "remark": "全覆盖移动端****************************************************************************",
                        "imgList": [{
                            "img": "/static/image/saasImg/space_img.png"
                        }, {
                            "img": "/static/image/banner1.png"
                        }, {
                            "img": "/static/image/saasImg/space_img.png"
                        }]
                    }, {
                        "title": "智能数据上报",
                        "select": false,
                        "remark": "智能数据上报****************************************************************************",
                        "imgList": [{
                            "img": "/static/image/saasImg/space_img.png"
                        }, {
                            "img": "/static/image/banner1.png"
                        }, {
                            "img": "/static/image/saasImg/space_img.png"
                        }]
                    }],
                featurImgList: [],
                selFeature: {},
                productSupList: [],
                featurListOne: '',
                isFlag: false,
                productAdvantageList: [],
                localData: {},
                //推荐搭配数据
                productCode: [
                    {
                        code: 'tool_online_wlhy',
                        id: null,
                        name: '在线调车',
                        productMatch: [
                            {
                                code: 'tool_online_pay',
                                name: '在线结算',
                            },
                            {
                                code: 'tool_big_data',
                                name: '数据可视化',
                            },
                            {
                                code: 'data_,dingwei,guiji,trace_forSaas',
                                name: '北斗坐标',
                            },
                        ]
                    },
                    {
                        code: 'tool_online_pay',
                        name: '在线结算',
                        id: null,
                        productMatch: [
                            {
                                code: 'resource_bank',
                                name: '银企云托管',
                            },
                        ]
                    },
                    {
                        code: 'tool_online_tracking',
                        name: '在线质控',
                        id: null,
                        productMatch: [
                            {
                                code: 'data_,dingwei,guiji,trace_forSaas',
                                name: '北斗坐标',
                            },
                        ]
                    },
                    {
                        code: 'tool_order',
                        name: '订单管理',
                        id: null,
                        productMatch: [
                            {
                                code: 'tool_contract_price',
                                name: '合同智能计费',
                            },
                            {
                                code: 'tool_online_dispatch',
                                name: '在线派单',
                            },
                        ]
                    },
                    {
                        code: 'tool_online_dispatch',
                        name: '在线派单',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '在线结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线质控',
                            // },
                        ]
                    },
                    {
                        code: 'tool_tms_settle',
                        name: '结算管理',
                        id: null,
                        productMatch: [
                            {
                                code: 'tool_contract_price',
                                name: '合同智能计费',
                            },
                        ]
                    },
                    {
                        code: 'tool_supplier',
                        name: '承运商管理',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '在线结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'tool_less_shipped',
                        name: '零担发运',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '在线结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'tool_contract_price',
                        name: '合同智能计费',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'tool_blling',
                        name: '开票管理',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'tool_owned_car',
                        name: '自有车管理',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'tool_bulk_freight',
                        name: '大批量发运',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'tool_big_data',
                        name: '数据可视化',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'resource_oil_card',
                        name: '油卡（实体/电子）',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'resource_insurance',
                        name: '货物运输保险',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'resource_etc_ticket',
                        name: '通行费票据',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'resource_signature',
                        name: '电子签章（E签宝）',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'resource_bank',
                        name: '银企云托管',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '在线结算',
                            // },
                        ]
                    },
                    {
                        code: 'resource_dri_levy',
                        name: '司机代征',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'data_,dingwei,guiji,trace_forSaas',
                        name: '北斗坐标',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'data_,send_sms',
                        name: '短信服务（天翼云）',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'data_online_shunt',
                        name: '车辆资源',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'serve_creation',
                        name: '共创服务',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'serve_online_help',
                        name: '网络货运申报协助',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'serve_online_retest',
                        name: '网络货运等保复测',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'serve_online_freight',
                        name: '网络货运平台运营咨询',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                    {
                        code: 'serve_ICP',
                        name: 'ICP电信增值年审',
                        id: null,
                        productMatch: [
                            // {
                            //     code: 'tool_online_pay',
                            //     name: '线上结算',
                            // },
                            // {
                            //     code: 'tool_online_tracking',
                            //     name: '在线跟踪',
                            // },
                        ]
                    },
                ],
                featurImgs: false
            };
        },

        watch: {
            sysConfig: function (nval, oval) {
                if (nval && nval.logoFile && nval.logoFile.filePath) {
                    var link = document.createElement("link");
                    link.type = "image/x-icon";
                    link.rel = "shortcut icon";

                    var serverHost = window.location.host;
                    if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
                        link.href = this.hrsaasImg;
                    } else if (serverHost.indexOf("saas") > -1) {
                        link.href = this.saasImg;
                    } else {
                        link.href = this.__ftpPath + nval.logoFile.filePath;
                    }
                    document.getElementsByTagName("head")[0].appendChild(link);
                }
            }
        },


        created() {

            this["config/setConfig"];

            if (this.$route.params && this.$route.params.spId != null) {
                this.curId = this.$route.params.spId;
            }

            if (this.$route.params && this.$route.query.code != null) {
                this.code = this.$route.query.code;
                if (this.code == 'resource_dri_levy' ||
                    this.code == 'resource_bank' ||
                    this.code == 'resource_signature' ||
                    this.code == 'resource_etc_ticket' ||
                    this.code == 'resource_oil_card' ||
                    this.code == 'resource_insurance'
                ) {
                    this.showProductQrCode = true;
                }
            }

            if (this.$route.params && this.$route.query.spName != null) {
                this.spName = this.$route.query.spName;
            }

            //产品详情
            this.getSaasProDetail();

        },

        mounted() {
            this.listProvider()

            this.getCompanyList();

            this.getProductAdvantageList(this.code);

            //推荐搭配
            this.getsupProList(this.code);

            //判断产品功能是否为空
            if (this.localData == null || this.localData.productFeatures == 0) {
                console.log("数据为空·")
                this.isFunctionData = true
            }

            if (this.localData != null && this.localData.params.length > 0) {
                this.isAdvantageData = true
            }

            //判断是否登录状态
            this.verificationLogin();

            //初始化 产品功能
            this.initProductFeatures();

        },

        methods: {


            listProvider(){
            Vue.http.post("provider/listProvider", {}).then((res) => {
          if (res.data.success) {
            this.providerList = res.data.data;
          }
        });
        },
            //进入
            mouseOver() {
                this.showQrCode = true;
            },

            //移除
            mouseLeave() {
                this.showQrCode = false;
            },



            goServiceProvideDetail(item){
                var url = window.location.protocol + "//" + window.location.host + "/hrsaas.html#/hrsaasServiceProvideDetail/" + item.id + "?spId=" + item.id;

                console.log(item,url,'item')

                window.open(url);
            },

            //初始化 产品功能
            initProductFeatures() {
                if (!this.isFunctionData) {
                    this.featurListOne = this.localData.productFeatures[0];
                    if (this.featurListOne.imgs != null) {
                        this.featurImgList = this.featurListOne.imgs;
                        if (this.featurImgList.length == 1) {
                            this.featurImgs = false
                        }
                        if (this.featurImgList.length > 1) {
                            this.featurImgs = true
                        }
                    }
                }
            },

            //前往试用订阅页面
            gotoSubscribeAction() {
                var url = null;
                if (this.isLogin) {
                    url = window.location.protocol + "//" + window.location.host + "/common.html#/saasSubscribeTab/" + this.curId + "/" + this.code;
                    window.location.href = url
                } else {
                    sessionStorage.setItem("beforeLoginUrl", "/common.html#/saasSubscribeTab/" + this.curId + "/" + this.code);
                    window.location.href = 'login.html#/login/hrsaas'
                }
            },

            goActive(index) {

                if (index == 0) {
                    this.$router.push({
                        name: "saasActive", params: {
                            index: index
                        }
                    });
                }
                if (index == 1) {
                    this.$router.push({
                        name: "saasActive", params: {
                            index: index
                        }
                    });
                }
                if (index == 2) {
                    this.$router.push({
                        name: "saasActive", params: {
                            index: index
                        }
                    });
                }

            },

            //前往试用订阅页面
            gotoTryAction() {
                var url = null;
                if (this.isLogin) {
                    url = window.location.protocol + "//" + window.location.host + "/common.html#/saasSubscribeTabTry/" + this.curId + "/" + this.code;
                    window.open(url)
                } else {
                    sessionStorage.setItem("beforeLoginUrl", "/common.html#/saasSubscribeTabTry/" + this.curId + "/" + this.code);
                    window.location.href = 'login.html#/login/hrsaas'

                }
            },

            //判断是否登录状态
            verificationLogin() {
                aEntityAPI.saasRequest("UserProductSubscribeService", "saasIndexConfig").then((res) => {
                    if (res.data.success && res.data.data.user != null) {
                        this.isLogin = true
                    }
                });
            },

            // 产品功能-优势
            getProductAdvantageList(priductCode) {
                var products = {
                    //外包交易
                    "hr_out_tran": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "需求发布",
                                contentList: [
                                    {
                                        content: "系统支持在线用工需求发布",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_out_tran_1.png"),
                                    },
                                ]
                            },
                            {
                                title: "需求匹配",
                                contentList: [
                                    {
                                        content: "用工需求匹配到人力资源方接单",
                                    },
                                ],
                                "select": false,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_out_tran_2.png"),
                                    },
                                ]
                            },
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/real_ime.png"),
                                title: '实时',
                                msg: '系统通过数据采集、信息和信息推送，实现了用工需求实时在线，业务实时匹配',
                            },
                            {
                                img: require("@/assets/saasImg/features/convenient.png"),
                                title: '便捷',
                                msg: '系统上线快速便捷，多租户架构即开即用，可视化配置中心支持快速自定义配置。',
                            },
                        ],
                    },
                    //外包结算
                    "hr_out_set": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "账单生成",
                                contentList: [
                                    {
                                        content: "自动根据需求生成对账单",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_out_set_1.png"),
                                    },
                                ]
                            },
                            {
                                title: "在线支付",
                                contentList: [
                                    {
                                        content: "账单通过在线银企通道完成B2B2C自动结算",
                                    },
                                ],
                                "select": false,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_out_set_2.png"),
                                    },
                                ]
                            },
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/efficient.png"),
                                title: '高效',
                                msg: '通过银企直联，实时在线自动收款和批量支付，极大提高工作效率。',
                            },
                            {
                                img: require("@/assets/saasImg/features/easy.png"),
                                title: '易用',
                                msg: '已对接农行、交行、光大、平安、、民生、兴业、江苏、徽商等银行，还包含易宝三方支持，用户可任选其中已有银行实现快速上线。',
                            },
                            {
                                img: require("@/assets/saasImg/features/security.png"),
                                title: '安全',
                                msg: '业务和财务数据一体化实现数据双向校验，并结合平台多层安全防护，保障资金支付安全性。',
                            },
                        ],
                    },
                    //自由职业者认定
                    "hr_freelance_identify": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "身份认证",
                                contentList: [
                                    {
                                        content: "支持OCR识别，三元素校验、人脸识别等多种方式进行角色身份认证",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_out_set_2.png"),
                                    },
                                ]
                            },
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/convenient.png"),
                                title: '便捷',
                                msg: '通过OCR自动识别，数据自动采集进行校验',
                            },
                        ],
                    },
                    //灵活用工在线接单
                    "hr_flexible_order": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "在线接单",
                                contentList: [
                                    {
                                        content: "支持各种灵工行业业务流程在线化，运用多种存储技术和定位技术完成工作的在线化提交、验收等流程",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_flexible_order_1.png"),
                                    },
                                ]
                            },
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/move.png"),
                                title: '移动化',
                                msg: '支持移动端接单，任务完成情况上传',
                            },
                            {
                                img: require("@/assets/saasImg/features/easy.png"),
                                title: '易用',
                                msg: '支持多种文件格式上传，文件、小视频、语音、图片格式',
                            },
                        ],
                    },
                    //灵活用工代开结算
                    "hr_flexible_set": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "代开结算",
                                contentList: [
                                    {
                                        content: "支持自然人代开，非全个税代缴，完税凭证在线查询",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_flexible_set_1.png"),
                                    },
                                ]
                            },
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/security.png"),
                                title: '安全',
                                msg: '数据通过税务系统，出具纸质完税凭证',
                            },
                        ],
                    },
                    //员工代发薪
                    "hr_employees_pay": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "线上发薪",
                                contentList: [
                                    {
                                        content: "支持工资条自定义一键导入，个人手机端实时查看工资条，7*24小时实时到账",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_employees_pay_1.png"),
                                    },
                                ]
                            },
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/easy.png"),
                                title: '易用',
                                msg: '已对接农行、交行、光大、平安、民生、兴业、江苏、徽商等银行，还包含易宝三方支持，用户可任选其中已有银行实现快速上线。',
                            },
                        ],
                    },
                    //自由职业者保障
                    "hr_freelance_assure": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "在线购买社保商保",
                                contentList: [
                                    {
                                        content: "支持小程序端在线购买异地社保商保服务",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_freelance_assure_1.png"),
                                    },
                                ]
                            },
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/convenient.png"),
                                title: '便捷',
                                msg: '支持小程序在线实时购买，资料在线提交',
                            },
                        ],
                    },
                    //入驻平台人力服务商
                    "hr_service_company": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "在线签约",
                                contentList: [
                                    {
                                        content: "支持用工方方和服务商合同在线签约。",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_service_company_1.jpg"),
                                    },
                                ]
                            },
                            {
                                title: "业务匹配",
                                contentList: [
                                    {
                                        content: "支持用工方发布的需求匹配到服务商接单 。",
                                    },
                                ],
                                "select": false,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_service_company_2.jpg"),
                                    },
                                ]
                            },
                            {
                                title: "凭证开具",
                                contentList: [
                                    {
                                        content: "支持服务商在线完成服务凭证开具。",
                                    },
                                ],
                                "select": false,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/hr_service_company_3.jpg"),
                                    },
                                ]
                            },
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/服务多样.png"),
                                title: '服务多样',
                                msg: '支持不同类型服务商入驻',
                            },
                            {
                                img: require("@/assets/saasImg/features/入驻方式多.png"),
                                title: '入驻方式多',
                                msg: '支持服务商在线入驻、展示页定制、API对接多种方式对接',
                            },
                        ],
                    },
                    //电子工资条
                    "hr_electronic_pays_lip": {
                        //产品功能
                        productFeatures: [
                            {
                                title: "线上查薪",
                                contentList: [
                                    {
                                        content: "通过短信和小程序通知员工，帮助员工随时随地查看工资明细。",
                                    },
                                ],
                                "select": true,
                                imgs: [
                                    {
                                        img: require("@/assets/hrsaas/detail/线上查薪.png"),
                                    },
                                ]
                            },
                           
                           
                        ],
                        //产品优势
                        params: [
                            {
                                img: require("@/assets/saasImg/features/convenient.png"),
                                title: '便捷',
                                msg: '帮助员工随时随地查看工资明细，助力HR提高工作效率，助力企业提升员工满意度！',
                            },
                        ],
                    },

                };
                this.localData = products[priductCode];
            },

            //选中特性
            createSelFeature(itemSel) {
                this.selFeature = itemSel;
                this.localData.productFeatures.forEach(item => {
                    if (item.title == itemSel.title) {
                        item.select = true;
                        this.featurImgNull = true
                        this.featurListOne = itemSel;
                        this.featurImgList = itemSel.imgs;
                        if (this.featurImgList == undefined) {
                            this.featurImgNull = false
                        }
                        if (this.featurImgList.length == 1) {
                            this.featurImgs = false
                        }
                        if (this.featurImgList.length > 1) {
                            this.featurImgs = true
                        }

                    } else {
                        item.select = false;
                    }
                });
            },

            // 获取入驻服务商列表 
            getCompanyList(){
                const params = {
                    'start': this.pageNo,
                    'limit': 9,
                    'q':{}
                };

                aEntityAPI.request("ZbrlApiService", "getCompanyList", {'qp': JSON.stringify(params) }).then(res => {
                    if (res.data.success) {
                        res.data.data.forEach(item=>{
                            if(item.recommend_file){
                                item.recommend_file = this.__ftpPath + item.recommend_file;
                            }
                        })
                        this.serviceList = this.serviceList.concat(res.data.data);
                        
                            console.log(res.data.data.length,'res.data')
                        // if(res.data.data.length == 0){
                        //      this.$message.warning('暂无更多服务商数据');
                        // }
                    }else {
                        this.$message.error('错误，失败');
                    }
                })

            },

            //推荐搭配跳转页面
            goSup(item) {
                var params = {productCode: item.code};
                if (item.id) {
                    var url = window.location.protocol + "//" + window.location.host + "/hrsaas.html#/hrsaasDetail/" + item.id + "?spid=" + item.id + "&code=" + item.code;
                    window.open(url);
                } else {
                    aEntityAPI
                        .saasRequest("UserProductSubscribeService", "queryProductByCode", params)
                        .then((res) => {
                            this.grid_loading = false;
                            if (res.data.success) {
                                var url = window.location.protocol + "//" + window.location.host + "/hrsaas.html#/hrsaasDetail/" + res.data.data + "?spid=" + res.data.data + "&code=" + item.code;
                                window.open(url);
                            }
                        })
                }
            },

            loadMore(){
                this.pageNo = this.pageNo + 8
                this.flag = true
                this.getCompanyList();
            },
            packUp(){
                this.pageNo = 0
                this.flag = false
                this.serviceList = [],
                this.getCompanyList();
            },

            //产品详情
            getSaasProDetail() {
                let that = this;
                var params = {proId: this.curId};
                aEntityAPI
                    .saasRequest("UserProductSubscribeService", "getSaasDetail", params)
                    .then((res) => {
                        this.grid_loading = false;
                        if (res.data.success) {
                            var dataDict = res.data.data;
                            if (dataDict.product != null) {

                                document.title = dataDict.product.spName;

                                this.product = dataDict.product;
                                this.experience = this.product.experience;

                                if (this.product.spCode == 'resource_dri_levy') {
                                    this.qr_code.qr_img = this.qr_code.xufang;
                                }
                                if (this.product.spCode == 'resource_bank') {
                                    this.qr_code.qr_img = this.qr_code.weiziling;
                                }
                                if (this.product.spCode == 'resource_signature') {
                                    this.qr_code.qr_img = this.qr_code.zhanglinlin;
                                }
                                if (this.product.spCode == 'resource_etc_ticket') {
                                    this.qr_code.qr_img = this.qr_code.zhaoyufeng;
                                }
                                if (this.product.spCode == 'resource_oil_card' || this.product.spCode == 'resource_insurance') {
                                    this.qr_code.qr_img = this.qr_code.xiekangfeng;
                                }
                                if (that.product.relyList != null && that.product.relyList.length > 0) {
                                    that.relyList = that.product.relyList;
                                }
                            }
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.data.msg ? res.data.msg : "查询产品明细数据失败",
                                type: "warning",
                            });
                        }
                    })
                    .catch((res) => {
                        this.grid_loading = false;
                    });
            },

            //推荐搭配
            getsupProList(productCode) {
                var supList = [];
                this.productCode.forEach(item => {
                    if (item.code == productCode) {
                        item.productMatch.forEach(e => {
                            supList.push(e)
                        });
                    }
                });
                this.supProList = supList;
            },
        },
    };
</script>
<style>


    .saas_detail_body_pro_sup_banner_null {
        font-size: 30px;
        color: #b1abab;
        padding: 50px 0px;
        background-color: #F3F5F8;
        width: 100%;
        text-align: center;
    }

    .product_features_is_null {
        height: 300px;
    }

    .product_features_is_not_null {
        /*height: 880px;*/
        height: 990px;
    }

    .saas_detail_body {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .saas_detail_body_top_info {
        position: relative;
        background-color: #d9d9d9;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        /*width: 100%;*/
        height: 550px;
        background-image: url(../assets/saasImg/banner_a.png);
        /*background-size: 100%;*/
        /*min-width: 1348px;*/
        margin-top: 55px;
    }

    .saas_detail_body_top_left_info {
        margin-left: 295px;
        z-index: 5;
        height: 375px;
    }

    .saas_detail_body_top_left_info_title_view {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .saas_detail_body_top_left_info_title_view_lab {
        color: #505050;
        font-size: 58px;
        font-weight: bold;
    }

    .saas_detail_body_top_left_info_title_view_exp {
        color: rgba(42, 130, 228, 1);
        border-radius: 18px;
        line-height: 150%;
        border: rgb(0 80 218) solid 1px;
        margin-left: 30px;
        padding-top: 12px;
        margin-top: 2px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #0068E9;
        line-height: 9px;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
        /*width: 90px;*/
        height: 21px;
    }

    .saas_detail_body_top_left_info_remark {
        margin-top: 10px;
        color: rgba(80, 80, 80, 1);
        font-size: 24px;
        line-height: 150%;
        text-align: left;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        /*margin-bottom: 15px;*/
        /*width: 75%;*/
        width: 670px;
    }

    .saas_detail_body_top_left_info_price {
        color: rgba(80, 80, 80, 1);
        font-size: 20px;
        line-height: 150%;
        text-align: left;
        display: flex;
        flex-direction: row;
        margin-top: 30px;
    }

    .saas_detail_body_top_left_info_price_develop {
        margin-top: 30px;
    }

    .saas_detail_body_price_color {
        color: #ff502e;
        font-size: 36px;
        padding-left: 2px;
    }

    .saas_detail_body_product_right_content {
        margin-bottom: 5px;
    }

    .saas_detail_body_top_left_info_btnview {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        /*margin-top: 20px;*/
    }

    .saas_detail_body_sup_product_view {
        margin-left: 230px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: -33px;
    }

    .saas_detail_body_top_left_info_btnview_button:hover {
        cursor: pointer;
    }

    .saas_detail_body_sup_product_view_title {
        font-size: 22px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #539BCC;
        line-height: 34px;
    }

    .saas_detail_body_sup_product_view_list {
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .saas_detail_body_product_tab_item:hover {
        cursor: pointer;
        box-shadow: 0px 0px 5px #484345;
    }

    .saas_detail_body_product_tab_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        padding: 10px 15px;
        background-color: #FFFFFF;
        border: 1px solid #929292;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #606060;
        line-height: 9px;
    }

    .saas_detail_body_product_tab_item:hover .saas_detail_body_product_tab_item_icon_lab {
        color: #ffffff;
    }

    .saas_detail_body_product_tab_item:hover .saas_detail_body_product_tab_item_lab {
        color: #ffffff;
    }

    .saas_detail_body_product_tab_item_icon_lab {
        padding: 2px 3px;
        background-color: #e5e5e5;
        color: #505050;
        font-size: 9px;
    }

    .saas_detail_body_product_tab_item_lab {
        color: #2a82e4;
        font-size: 14px;
    }

    .saas_detail_body_top_right_img {
        width: 100%;
        z-index: 3;
        left: 0;
        top: 0;
        position: absolute;
    }

    .saas_detail_body_discount {
        height: 80px;
        background-color: #c6c6c6;
        width: 100%;
    }

    .saas_detail_body_product {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        background-color: #F3F5F8;
        height: auto;
        /*height: 880px;*/
        /*margin-top: 30px;*/
    }

    .saas_detail_body_product_tab_title {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        color: rgba(80, 80, 80, 1);
        text-align: center;
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #000000;
        height: 50px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .saas_detail_body_product_tab_title_one {
        width: 100px;
        height: 1px;
        background-color: #DCDCDE;
    }

    .saas_detail_body_product_tab_title_three {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #000000;
        margin: 0 10px;
    }

    .saas_detail_body_product_tab_title_two {
        width: 4px;
        height: 4px;
        background-color: #DCDCDE;
        border-radius: 2px;
        margin-top: 2px;
    }

    .saas_detail_body_product_body {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 80%;
        min-width: 1200px;
        /*min-height: 480px;*/
        height: auto;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .saas_detail_body_product_left_list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 230px;
        height: 100%;
        background-color: #F3F5F8;
    }

    .saas_detail_body_featur_item {
        color: #4D5A73;
        font-size: 14px;
        text-align: center;
        min-width: 190px;
        margin-bottom: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 4px;
    }

    .saas_detail_body_featur_item_t {
        background: -webkit-linear-gradient(top, rgba(233, 237, 243, 1), rgba(246, 248, 250, 1));
    }

    .saas_detail_body_featur_item_one {
        min-width: 231px;
        border-right-style: none;
        z-index: 2;
        background-color: #F4F6F9;
        border: 2px solid #FFFFFF;
        border-right-style: none;
    }

    .saas_detail_body_featureTitleTive {
        width: 100%;
    }

    .saas_detail_body_product_right {
        flex: 2;
        color: rgba(80, 80, 80, 1);
        background-color: #F4F6F9;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border: 2px solid #FFFFFF;
        box-shadow: 4px 4px 8px 8px rgba(233, 237, 243, 1);
        /*height: 650px;*/
        /*height: 760px;*/
        /*min-height: 500px;*/
        height: auto;
        padding-bottom: 20px;
    }

    .saas_detail_body_product_right_imgs {
        /*flex: 2;*/
        width: 80%;
        /*margin: 30px auto;*/
        margin: 0% 10%;

    }

    .saas_detail_body_product_right_img {
        width: 97%;
        margin: 3%;
        background-size: 100% 100%;
    }

    .saas_detail_body_hidden_view {
        width: 0px;
        height: 0px;
        overflow: hidden;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .saas_detail_body_product_right_point {
        width: 8px;
        height: 8px;
        background-color: #9BA6B7;
        border-radius: 4px;
        float: left;
        margin-top: 5px;
    }

    .saas_detail_body_product_right_content_text {
        font-size: 15px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #4D5A73;
        margin-left: 20px;

        word-break: break-all;
        word-wrap: break-word;
        white-space: pre-wrap;
    }

    .saas_detail_body_featureTive {
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    .saas_detail_body_product_right_lab {
        color: rgba(80, 80, 80, 1);
        font-size: 14px;
        height: 40px;
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        text-align: left;
    }

    .saas_detail_body_pro_sup {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 30px;
    }

    .saas_detail_body_pro_sup_banner {
        text-align: center;
        margin-bottom: 40px;
    }

    .saas_detail_body_pro_sup_banner_img {
        margin-top: 36px;
    }
    .saas_detail_body_ser_pro_footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .saas_detail_body_pro_sup_banner_zero {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .saas_detail_body_pro_sup_banner_two {
        font-family: Source Han Sans SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        font-size: 25px;
        margin-top: 20px;
    }

    .saas_detail_body_pro_sup_banner_three {
        width: 310px;
        height: 84px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
        margin: 20px auto;
        /*超出换行*/
        word-break: break-all;
        word-wrap: break-word;
        white-space: pre-wrap;

        line-height: 26px;
    }

    .saas_detail_body_product_right_text {
        margin-top: 28px;
        margin-left: 64px;
        font-size: 20px;
        color: #F4F6F9;
        font-family: Source Han Sans SC;
        background-color: rgb(244, 246, 249);
        margin-bottom: 10px;
    }

    .saas_detail_body_featur_item {
        cursor: pointer;
    }

    .saas_detail_body_top_left_info_one {
        float: right;
        z-index: 5;
    }

    .saas_detail_body_top_left_info_remark_one {
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #606060;
        line-height: 30px;
        /*width: 720px;*/
        /*height: 87px;*/
        max-height: 87px;
    }

    .saas_detail_body_pro_sup_banner_one {
        margin-left: 10px;
        width: 400px;
        min-width: 316px;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        box-shadow: 0px 2px 43px 0px rgba(21, 113, 179, 0.07);
        opacity: 0.97;
        border-radius: 8px;
    }

    .saas_detail_body_discount_body {
        width: 100%;
        position: absolute;
        top: 440px;
        left: 0px;
        background-color: #ffffff66;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 110px;
        z-index: 12;
    }

    .saas_detail_body_discount_body_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 100%;  
    }

    .saas_detail_body_discount_body_item:hover {
        background-color: #ffffff66;
        cursor: pointer;
    }

    .saas_detail_body_discount_body_item:hover .saas_detail_body_discount_body_item_top_title {
        color: #2468f2;
    }

    .saas_detail_body_discount_body_item_top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .saas_detail_body_discount_body_item_top_title {
        color: #000;
        font-size: 24px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        margin-right: 5px;
    }

    .saas_detail_body_discount_body_item_bottom {
        color: #666666;
        font-size: 16px;
        width: 85%;
        height: auto;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
        word-wrap: break-word;
        overflow: hidden;
    }

    #saas_detail_body_product img {
        max-width: 900px;
    }

    .saas_detail_body_top_left_info_btnview_button .goto_subscribe {
        width: 130px;
        height: 38px;
        font-size: 22px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        background-color: #0050DA;
        color: #FFFFFF;
        vertical-align: middle;
    }

    .saas_detail_body_top_left_info_btnview_button .goto_try {
        width: 133px;
        height: 37px;
        font-size: 22px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        vertical-align: middle;
    }

    .saas_detail_body_top_left_info_btnview_button_develop {
        height: 100px;
    }

    .saas_detail_body_top_left_info_btnview_button .develop_button {
        width: 133px;
        height: 37px;
        font-size: 22px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        background-color: #8c939d;
        border-radius: 18.5px;
        color: #FFFFFF;
        padding-bottom: 7px
    }

    .saas_detail_body_top_left_info_btnview_button {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .saas_detail_body_top_left_info_btnview_button .develop_button_title {
        margin-left: 12px;
        color: #539BCC;
        font-size: 22px;
        font-family: Source Han Sans SC;
    }

    .saas_detail_body_top_left_info_btnview_button .develop_button_qr_code_min {
        margin-left: 12px;
        margin-top: 5px;
    }

    .saas_detail_body_top_left_info_btnview_button .develop_button_qr_code_max {
        margin-left: 12px;
    }

    .saas_detail_body_top_left_info_btnview_button:hover {
        cursor: pointer;

    }

    .product_info_rely:hover {
        cursor: pointer;

    }

    .saas_detail_body_top_left_info_btnview_button .develop_button_qr_code_max_mouseover {
        opacity: 0;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
    }

    .saas_detail_body_top_left_info_btnview_button .develop_button_qr_code_max_mouseleave {
        opacity: 1;
        -webkit-transition: all 1s;
        -moz-transition: all 1s;
        -ms-transition: all 1s;
        -o-transition: all 1s;
        transition: all 1s;
    }

    .qr_code_button_top {
        margin-top: 30px;
    }

    .saas_detail_body_ser_pro{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 30px;
        font-family: Source Han Sans SC;
    }
    .saas_detail_body_ser_pro_body{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        width: 80%;
        min-width: 1000px;
        height: auto;
        margin-bottom: 30px;
    }

    .saas_detail_body_ser_pro_body_item{
        width:33%;
        border:1px solid #DCDCDC; 
        margin:0px 0px -1px -1px;
    }
    .saas_detail_body_ser_pro_body_item_detail{
        width:96%; 
        margin:0 auto;
        margin-bottom:2%;
        margin-top:2%;
        background:#fff;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .saas_detail_body_ser_pro_body_item_detail_img{
        width: 100%; 
        height: 160px;
        margin:0 auto;
    }
    .saas_detail_body_ser_pro_body_item_detail_title{
        width: 92%;
        margin:0 auto;
        display:flex;
        align-items:center;
        justify-content: space-between;
        height:60px;
    }
    .saas_detail_body_ser_pro_body_item_detail_title_left{
        font-weight: 600;
        font-size: 18px;
        color:#333;
    }
    .saas_detail_body_ser_pro_body_item_detail_title_right{
        padding: 6px 12px;
        background: #FBCD57;
        color: #fff;
        font-size: 12px;
        border-radius: 7px;
    }{

    }
    .saas_detail_body_ser_pro_body_item_detail_remark{
        width: 92%;
        margin:0 auto;
        height:72px;
        font-size: 16px;
        color: #999;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }



</style>