<template>
    <div class="saas_active_body">
        <div class="top_view">
            <img class="top_view_bg" src="@/assets/hrsaas/active/active_top.png"/>
        </div>
        <div class="center_view" id="target">
            <!--<img class="center_view_bg" @click="goIndex" src="@/assets/hrsaas/active/active_bottom.png"/>-->
            <img class="center_view_bg" @click="goIndex" src="@/assets/saasImg/SaaS活动页面调整.jpg"/>
        </div>
    </div>
</template>


<script>
    import aEntityAPI from "@/api/sysAPI.js";

    export default {
        components: {},
        computed: {},

        data() {
            return {
                timeOverNum: 23,
                activeList: [
                    {
                        title: "首单7折",
                        saleRemark:
                            "前100名新用户订阅工具类首单7折起，目前名额有限，先到先得。",
                        remark: "若您订阅一年某产品共原价10000元，折后只需支付7000元",
                        discount: 30,
                    },
                    {
                        title: "首单7折",
                        saleRemark:
                            "前100名新用户订阅工具类首单7折起，目前名额有限，先到先得。",
                        remark: "若您订阅一年某产品共原价10000元，折后只需支付7000元",
                        discount: 30,
                    },
                    {
                        title: "首单7折",
                        saleRemark:
                            "前100名新用户订阅工具类首单7折起，目前名额有限，先到先得。",
                        remark: "若您订阅一年某产品共原价10000元，折后只需支付7000元",
                        discount: 30,
                    },
                    {
                        title: "首单7折",
                        saleRemark:
                            "前100名新用户订阅工具类首单7折起，目前名额有限，先到先得。",
                        remark: "若您订阅一年某产品共原价10000元，折后只需支付7000元",
                        discount: 30,
                    },
                ], //活动的数据
            };
        },

        created() {
        },

        mounted() {
            this.getSaasProDetail();
        },

        methods: {
            //获取saas首页的数
            getSaasProDetail(spId) {
                var params = {proId: this.curId};
                aEntityAPI
                    .saasRequest("UserProductSubscribeService", "getActive", params)
                    .then((res) => {
                        this.grid_loading = false;
                        if (res.data.success) {
                            var dataDict = res.data.data;
                        }
                    })
                    .catch((res) => {
                        this.grid_loading = false;
                    });
            },

            goIndex(){
                this.$router.push({
                    name: "hrsaas", 
                    params: {
                        index: true
                    }
                });
            }
        },
    };
</script>
<style>

    .a_link {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .saas_active_body {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .saas_active_body .top_view {
        width: 100%;

    }

    .saas_active_body .top_view_bg {
        width: 100%;
        height: 100%;
        object-fit: contain;

    }

    .saas_active_body .center_view {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -2px;
    }

    .saas_active_body .center_view_bg {
        width: 100%;
        object-fit: contain;
    }

    .saas_active_body .center_view_body {
        width: 800px;
        height: 1200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* z-index: 10; */
    }

    .saas_active_body .center_view_body_time {
        font-size: 60px;
        font-family: MF JianHei (Noncommercial);
        font-weight: 400;
        font-style: italic;
        color: #700c23;
        line-height: 80px;
    }

    .saas_active_body .center_view_cell_body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .saas_active_body .active_cell_left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .saas_active_body .active_cell_right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .saas_active_body .active_cell_title {
        font-size: 42px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #ee3c4c;
        line-height: 50px;
    }

    .saas_active_body .active_cell_sale {
        font-size: 26px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #ee3c4c;
        line-height: 30px;
    }

    .saas_active_body .active_cell_remark {
        font-size: 28px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #621c1c;
        line-height: 30px;
    }

    .saas_active_body .active_cell_btn_view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 380px;
        height: 70px;
        border-radius: 35px;
        background-color: #ee3c4c;
    }

    .saas_active_body .active_cell_btn_view_discount {
        font-size: 38px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #ffffff;
    }

    .saas_active_body .active_cell_btn_view_submit {
        margin-right: 15px;
        border-radius: 20px;
        background-color: #ffffff;
        padding: 5px 10px;
        width: 122px;
        height: 40px;
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        font-family: Source Han Sans SC;
        font-weight: 500;
        font-style: italic;
        color: #ee3c4c;
    }

    .home-menu ul .active {
        /*border-bottom: 2px solid #409eff;*/
    }
</style>