import Vue from 'vue'
import store from '@/store'

export default {
    /**
     *通用实例列表查询
     * @param {Object} params 
     * params = {
                    start: this.grid_pageSize * (this.grid_pageNo - 1),
                    limit: this.grid_pageSize,
                    cls:'com.gsh56.tower.bs.Addr',
                    fields:["id","name","tel","linkman","shipper.name","organ.name"],
                    sort:'linkman',
                    q: {
                        "linkman__like":"王"
                    }
                }
     */
    pageEntity(params) {
        return Vue.http.post("api/ZbrlApiService/pageEntity", {
            "@ProductCode": "zbrl",
            q: JSON.stringify(params)
        });
    },
    /**
     * 通用Map查询
     * @param {Object} params 
     */
    pageMap(params) {
        return Vue.http.post("api/ZbrlApiService/pageMap", {
            "@ProductCode": "zbrl",
            q: JSON.stringify(params)
        });
    },
    /**
     * 
     * @param {*} cls   com.gsh56.tower.oms.Order
     * @param {*} cmd  ord/order.list4shipper
     * @param {*} paramMap 
     */
    findList(cls, cmd, paramMap) {
        return Vue.http.post("api/ZbrlApiService/findList", {
            "@ProductCode": "zbrl",
            cls: cls,
            cmd: cmd,
            paramMap: JSON.stringify(paramMap)
        });
    },
    /**
     * 自定义查询
     * @param {*} cls com.gsh56.tower.oms.Order
     * @param {*} cmd  ord/order.list4shipper
     * @param {*} qp   {q:{}}
     */
    pageMapByCmd(cls, cmd, qp) {
        return Vue.http.post("api/ZbrlApiService/findPage", {
            "@ProductCode": "zbrl",
            cls: cls,
            cmd: cmd,
            qp: JSON.stringify(qp)
        });
    },
    /**
     * 获取单个实体
     * @param {String} cls  class com.gsh56.tower.bs.Addr
     * @param {Number} id  主键 1
     * @param {Number} depth dto关联深度 1
     */

    get(cls, id, depth) {
        return Vue.http.post("api/ZbrlApiService/get", {
            "@ProductCode": "zbrl",
            cls: cls,
            id: id,
            depth: depth
        });
    },
    /**
     * 持久化实体
     * @param {String} cls class  com.gsh56.tower.bs.Addr
     * @param {Object} json  json
     *   const json ={
                //     name:"小王",
                //     linkman:'小王',
                //     mobile:'13909920093',
                //     shipper:{id:659},
                //     organ:{id:820}
                // }
     * 
     * 
     */
    save(cls, json) {
        return Vue.http.post("api/ZbrlApiService/saveWithJson", {
            "@ProductCode": "zbrl",
            cls: cls,
            json: JSON.stringify(json)
        });
    },
    /**
     * 修改方法 不覆盖未传入的json参数
     * @param {String} cls class  com.gsh56.tower.bs.Addr
     * @param {Object} json  json
     *   const json ={
                //     name:"小王",
                //     linkman:'小王',
                //     mobile:'13909920093',
                //     shipper:{id:659},
                //     organ:{id:820}
                // }
     * 
     * 
     */
    update(cls, json) {
        return Vue.http.post("api/ZbrlApiService/updateWithJson", {
            "@ProductCode": "zbrl",
            cls: cls,
            json: JSON.stringify(json)
        });
    },
    /**
     *  删除实体
     * @param {String} cls class com.gsh56.tower.bs.Addr
     * @param {Array} ids  id 数组 [1,2]
     */
    remove(cls, ids) {
        //return Vue.http.post("api/WlhyApiService/delete", {cls:cls, id:ids.join(',')});
        return Vue.http.post("api/ZbrlApiService/delete", {
            "@ProductCode": "zbrl",
            cls: cls,
            id: ids
        });
    },

    /**
     *通用服务调用
     * @param {Object} svc 
     * @param {Object} method 
     * @param {Object} params 
     */
    request(svc, method, params) {
        if(params){
            params["@ProductCode"]="zbrl"
        }else{
            params = {"@ProductCode": "zbrl"}
        }
        return Vue.http.post("api/" + svc + "/" + method, params);
    },
    /**
     * 
     * @param {*} searchapi 最好与列表查询接口保持一致
     * @param {*} params 参数 
     * @param {*} fileName 文件名
     */
    exportExcel(fileName, searchapi, params, isFilter) {
        isFilter = isFilter || true;
        let columns = JSON.parse(params.columns);
        if(params){
            params["@ProductCode"]="zbrl"
        }else{
            params = {"@ProductCode": "zbrl"}
        }
        if (isFilter) { //打开字段筛选
            //缓存键值
            const key = "export_" + store.getters['user/userinfo']['id'] + '_' + searchapi + '_' + fileName
            Vue.prototype.$exportColFilter({
                items: columns.columns,
                key: key,
                onConfirm: (col) => {
                    if (col.length > 0) {
                        const checkColumns = {
                            fileName: columns.fileName,
                            columns: col
                        }
                        params.columns = JSON.stringify(checkColumns)
                    }

                    this.doExport(fileName, searchapi, params)
                }
            })
        } else {
            this.doExport(fileName, searchapi, params)
        }


    },
    doExport(fileName, searchapi, params) {
        Vue.http.post("api/export/" + searchapi, params, {
            responseType: 'blob'
        }).then((response) => {
            const blob = new Blob([response.data]);
            if ('download' in document.createElement('a')) {
                const link = document.createElement('a')
                link.download = fileName || 'default.xls'
                link.style.display = 'none'
                link.href = window.URL.createObjectURL(blob);
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) // 释放URL 对象
                document.body.removeChild(link)
            } else {
                navigator.msSaveBlob(blob, fileName)
            }
        })
    },
    //单文件下载
    downloadFile(file){
        const f = {
            id: file.id,
            guid: file.guid,
            extension:file.extension,
            mimeType: file.mimeType,
            filePath: file.filePath,
            fileName: file.fileName
        }
        Vue.http.post("file/downloadFile", JSON.stringify(f), {
            responseType: 'blob',
            headers: { "Content-Type": "application/json;charset=utf-8" }
        }).then((response) => {
            if (response.status != null && response.status == 200) {
                this.doDownload(response,f.fileName)
            }else{
                Vue.prototype.$message.error('下载文件失败')
            }

        })
    },

    /**
     * 打包下载文件
     * @param {*} files  Array
     * @param {*} name 
     */
    downloadFilesZip(files) {
        files.map(item=>item.status=null)//
        Vue.http.post("file/downloadFilesZip", JSON.stringify(files), {
            responseType: 'blob',
            headers: { "Content-Type": "application/json;charset=utf-8" }
        }).then((response) => {
            if (response.status != null && response.status == 200) {
                this.doDownload(response,'default.zip')
            }else{
                Vue.prototype.$message.error('下载文件失败')
            }

        })
    },

    doDownload(response,name){
        const blob = new Blob([response.data]);
        if ('download' in document.createElement('a')) {
            const link = document.createElement('a')
            link.download = name
            link.style.display = 'none'
            link.href = window.URL.createObjectURL(blob);
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href) // 释放URL 对象
            document.body.removeChild(link)
        } else {
            navigator.msSaveBlob(blob,name)
        }
    },


    upload(action, params) {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        }
        return Vue.http.post(action, params, config);
    },
    /**
     *  文件通用上传
     * @param {String} filedata 文件
     * @param {Boolean} thumb  预览图
     * @param {Boolean} waterMark  水印
     */
    fileUpload(filedata, thumb, waterMark) {
        return Vue.http.post("api/file/upload", {
            filedata: filedata,
            thumb: thumb,
            waterMark: waterMark
        });
    }
}