<template>
    <div class="hr_saas_index_body">
        <div id="home" class="title_banner">
            <div class="title_banner_body">
                <el-carousel :interval="5000" height="600px">
                    <el-carousel-item>
                        <div class="home_banner_bg">
                            <img class="home_banner_bg_imgB" src="@/assets/hrsaas/index/hr_banner_light.png"/>
                            <img class="home_banner_bg_imgC" src="@/assets/hrsaas/index/hr_banner_main.png"/>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="discount_body">
                <template v-for="(item, index) in discountList">
                    <div class="discount_body_item" :key="index" @click="goActive(index)">
                        <div class="discount_body_item_top">
                            <div class="discount_body_item_top_title">{{ item.title }}</div>
                            <img v-if="item.img" style="width: 23px; height: 29px" :src="item.img"/>
                        </div>
                        <div class="discount_body_item_bottom">{{ item.msg }}</div>
                    </div>
                </template>
            </div>
        </div>
        <div id="product" class="product">
            <div id="product_saas_my" class="product_body" v-if="tentProList != null && tentProList.length > 0">
                <template v-for="(item, index) in tentProList">
                    <div class="tenant_pro_body" :key="index">
                        <div class="tenant_pro_menum">
                            <template v-for="(typeItem, typeIndex) in item.typeList">
                                <el-button :key="typeIndex" class="tenant_pro_type_item"
                                           :class="{ tenant_pro_type_item_sel: typeItem.select }"
                                           @click="proTabClick(item, typeItem)">
                                    {{ typeItem.name }}
                                </el-button>
                            </template>
                        </div>

                        <div class="tenant_pro_cell">
                            <template v-for="(cellItem, cellIndex) in item.productList">
                                <div class="tenant_pro_cell_item" :key="cellIndex"
                                     v-if="selTenProTag == null || (selTenProTag != null && selTenProTag == item.tentInfo.name + cellItem.type)">
                                    <div class="tenant_pro_cell_item_title">
                                        <div class="tenant_pro_cell_item_title_line"></div>
                                        <div class="tenant_pro_cell_item_title_lab"> {{ cellItem.type }}</div>
                                        <div class="tenant_pro_cell_item_title_line"></div>
                                    </div>
                                    <div class="tenant_pro_cell_item_body">
                                        <template v-for="(proItem, proIndex) in cellItem.items">
                                            <div :key="proIndex" class="tenant_pro_cell_item_body_item">

                                                <div class="tenant_pro_cell_item_body_item_saleTag">
                                                    <img v-if="proItem.tagImg" style="width: 100%"
                                                         class="tenant_pro_cell_item_body_item_saleTag_img"
                                                         :src="proItem.tagImg"/>
                                                </div>

                                                <div class="tenant_pro_cell_item_body_item_cont">
                                                    <div class="tenant_pro_cell_item_body_item_top"
                                                         @click="prductClick(proItem)">
                                                        <div class="tenant_pro_cell_item_body_item_title">
                                                            <div class="tenant_pro_cell_item_body_item_name"> {{
                                                                proItem.spName | ellipsisTitle }}
                                                            </div>
                                                            <div class="tenant_pro_cell_item_body_item_price">
                                                                <span style="color: #E99450;font-size: 24px;font-weight: bold"> {{ proItem.unitPrice  | ellipsisTitle }}</span>
                                                                <span style="color: #B1B3B4;font-size: 15px"> {{  proItem.unitName  | ellipsisTitle }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="tenant_pro_cell_item_body_item_top_info">
                                                            <el-tooltip :disabled="proItem.spRemark && proItem.spRemark.length <28"
                                                                        :content="proItem.spRemark"
                                                                        placement="top" effect="light">
                                                                <div class="tenant_pro_cell_item_body_item_remark">
                                                                    {{ proItem.spRemark | ellipsisRemark }}
                                                                </div>
                                                            </el-tooltip>

                                                            <div class="tenant_pro_cell_item_body_item_tag">
                                                                <template
                                                                        v-for="(tagItem, tagIndex) in proItem.tagList">
                                                                    <div :key="tagIndex"
                                                                         class="tenant_pro_cell_item_body_item_tag_item">
                                                                        {{ tagItem.title }}
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div class="tenant_pro_cell_item_body_item_top_proImg">
                                                            <img style="height:100%;width:100%"
                                                                 :src="proItem.proBgImg"/>
                                                        </div>
                                                    </div>

                                                    <template
                                                            v-if="(proItem.spsStatusValue < 0 || proItem.spsStatusValue == null) && proItem.statusValue == 10 ">
                                                        <div class="tenant_pro_cell_item_body_item_info">
                                                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                                                            <span class="tenant_pro_cell_item_body_item_info_left"
                                                                  v-html="proItem.rightInfo"></span>
                                                        </div>
                                                        <div class="tenant_hove_show">
                                                            <div @click.stop="gotoSubscribeAction(proItem)"
                                                                 class="tenant_hove_show_subscription">立即订阅
                                                            </div>
                                                            <div @click.stop="prductClick(proItem)"
                                                                 class="tenant_hove_show_subscription_detail">订阅详情
                                                            </div>
                                                        </div>
                                                    </template>

                                                    <template v-else>
                                                        <div class="tenant_pro_cell_item_body_item_info_nore"
                                                             v-if="proItem.statusValue == 10">
                                                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.leftInfo }}</span>
                                                            <span class="tenant_pro_cell_item_body_item_info_left"
                                                                  v-html="proItem.rightInfo"></span>
                                                        </div>
                                                        <div class="tenant_pro_cell_item_body_item_info_nore"
                                                             v-if="proItem.statusValue == 1">
                                                            <span class="tenant_pro_cell_item_body_item_info_left">{{ proItem.statusName }}</span>
                                                            <span class="tenant_pro_cell_item_body_item_info_left"
                                                                  v-html="proItem.rightInfo"></span>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
            <div class="product_body_empty" v-show="showExpect">
                <span style="color:#ddd;font-size:32px;font-weight:500">敬请期待</span>
            </div>
        </div>

        <div id="custCase" class="cust_case">
            <div class="cust_title">客户案例</div>
            <div class="cust_body">
                <el-carousel :interval="5000" height="350px">
                    <el-carousel-item v-for="(item, index) in custCaseList" :key="index">
                        <div class="img_array_body">
                            <img class="img_array_body_item" v-for="(imgItem, itemIndex) in item.imgs"
                                 :key="itemIndex" :src="imgItem.img"/>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
    </div>
</template>


<script>
    import aEntityAPI from "@/api/sysAPI.js";
    import {mapActions, mapGetters} from "vuex";

    export default {
        components: {},
        computed: {
            ...mapGetters({
                sysConfig: "config/sysConfig"
            }),
            ...mapActions(["config/setConfig"])
        },

        data() {
            return {
                hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
                saasImg: require("@/assets/saas_logo.png"),
                isLogin: false,
                showExpect: false,
                titleOrRemarkColor: false,
                topIndex: 1,
                searchValue: "",
                tenantDomain: "",
                selTenProTag: null, //当权选中工具，服务等的标志
                discountList: [
                    {
                        tag: 1,
                        title: "满年8折",
                        msg: "一次性订阅12月按8折收费",
                        img: "",
                    },

                    {
                        tag: 2,
                        title: "满减钜惠",
                        msg: "单次订单满2万按照8折计费",
                        img: "",
                    },
                    // {
                    //     tag: 3,
                    //     title: "限时7折",
                    //     msg: "工具类型产品按照7折计费",
                    //     img: "",
                    // },
                ],
                meunList: [
                    {
                        tag: 2,
                        select: true,
                        name: "运输类",
                        code: "wlhy,tms",
                        img: require("@/assets/saasImg/run_saas.png"),
                        imgSel: require("@/assets/saasImg/run_saas_sel.png"),
                    },
                    {
                        tag: 3,
                        select: false,
                        name: "人力类",
                        code: "zbrl",
                        img: require("@/assets/saasImg/zb_saas.png"),
                        imgSel: require("@/assets/saasImg/zb_saas_sel.png"),
                    },
                ],
                tentProList: [],
                custCaseList: [
                    {
                        tag: 1,
                        imgs: [
                            {
                                name: "大自然家居",
                                img: require("@/assets/hrsaas/cust/logo01.png"),
                            },
                            {
                                name: "找高人",
                                img: require("@/assets/hrsaas/cust/logo02.png"),
                            },
                            {
                                name: "威琅集团",
                                img: require("@/assets/hrsaas/cust/logo03.png"),
                            },
                            {
                                name: "波奇宠物",
                                img: require("@/assets/hrsaas/cust/logo04.png"),
                            },
                            {
                                name: "",
                                img: require("@/assets/hrsaas/cust/logo05.png"),
                            },
                            {
                                name: "",
                                img: require("@/assets/hrsaas/cust/logo06.png"),
                            },
                            {
                                name: "",
                                img: require("@/assets/hrsaas/cust/logo07.png"),
                            },
                            {
                                name: "西安合兴",
                                img: require("@/assets/hrsaas/cust/logo08.png"),
                            },
                        ],
                    },
                    // {
                    //     tag: 2,
                    //     imgs: [
                    //         {
                    //             name: "浩悦网络",
                    //             img: require("@/assets/saasImg/cust/logo17.png"),
                    //         },
                    //         {
                    //             name: "携运网",
                    //             img: require("@/assets/saasImg/cust/logo10.png"),
                    //         },
                    //         {
                    //             name: "德润供应链",
                    //             img: require("@/assets/saasImg/cust/logo11.png"),
                    //         },
                    //         {
                    //             name: "胜利智云",
                    //             img: require("@/assets/saasImg/cust/logo12.png"),
                    //         },
                    //         {
                    //             name: "泽运通物流",
                    //             img: require("@/assets/saasImg/cust/logo13.png"),
                    //         },
                    //         {
                    //             name: "琪旺",
                    //             img: require("@/assets/saasImg/cust/logo14.png"),
                    //         },
                    //         {
                    //             name: "海科共生",
                    //             img: require("@/assets/saasImg/cust/logo15.png"),
                    //         },
                    //         {
                    //             name: "熠路顺运输",
                    //             img: require("@/assets/saasImg/cust/logo16.png"),
                    //         },
                    //     ],
                    // },
                ],
                productCodeImgList: [
                    
                    //工具包
                    {
                        code: "hr_out_tran",
                        img: require("@/assets/hrsaas/index/hr_out_tran.png"),
                        name: "外包交易",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "实时",
                            },
                            {
                                title: "便捷",
                            },
                        ],
                    },
                    {
                        code: "hr_out_set",
                        img: require("@/assets/hrsaas/index/hr_out_set.png"),
                        name: "外包结算",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "高效",
                            },
                            {
                                title: "易用",
                            },
                            {
                                title: "安全",
                            },
                        ],
                    },
                    {
                        code: "hr_freelance_identify",
                        img: require("@/assets/hrsaas/index/hr_freelance_identify.png"),
                        name: "自由职业者认定",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "便捷",
                            },
                        ],
                    },
                    {
                        code: "hr_flexible_order",
                        img: require("@/assets/hrsaas/index/hr_flexible_order.png"),
                        name: "灵活用工在线接单",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "移动化",
                            },
                            {
                                title: "易用",
                            },
                        ],
                    },
                    {
                        code: "hr_flexible_set",
                        img: require("@/assets/hrsaas/index/hr_flexible_set.png"),
                        name: "灵活用工代开结算",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "安全",
                            },
                        ],
                    },
                    {
                        code: "hr_employees_pay",
                        img: require("@/assets/hrsaas/index/hr_employees_pay.png"),
                        name: "员工代发薪",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "易用",
                            },
                        ],
                    },
                    {
                        code: "hr_freelance_assure",
                        img: require("@/assets/hrsaas/index/hr_freelance_assure.png"),
                        name: "自由职业者保障",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "便捷",
                            },
                        ],
                    },
                    {
                        code: "hr_service_company",
                        img: require("@/assets/hrsaas/index/zbrl_fws.png"),
                        name: "入驻平台人力服务商",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "服务多样",
                            },
                            {
                                title: "入驻方式多",
                            },
                        ],
                    },
                    {
                        code: "hr_electronic_pays_lip",
                        img: require("@/assets/hrsaas/index/工资条.png"),
                        name: "电子工资条",
                        // tagImg: require("@/assets/saasImg/sup_tag.png"),
                        proFeature: [
                            {
                                title: "便捷",
                            },
                        ],
                    },
                    
                    
                    //资源


                    //数据


                    //服务

                ],
                typeList: [
                    // {tag: 0, select: true, name: "全部", value: null},
                    // {tag: 1, select: false, name: "工具", value: 1},
                    // {tag: 2, select: false, name: "数据", value: 2},
                    // {tag: 3, select: false, name: "资源", value: 3},
                    // {tag: 4, select: false, name: "服务", value: 4},

                    // {tag: 1, select: false, name: "工具", value: 1},
                    // {tag: 2, select: false, name: "在线服务", value: 2},
                    // {tag: 3, select: false, name: "线下服务", value: 3},//数据 线上
                ],
            };
        },

        created() {
            this["config/setConfig"];
        },

        watch: {

            sysConfig: function (nval, oval) {
                if (nval && nval.logoFile && nval.logoFile.filePath) {
                    var link = document.createElement("link");
                    link.type = "image/x-icon";
                    link.rel = "shortcut icon";

                    var serverHost = window.location.host;
                    if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
                        link.href = this.hrsaasImg;
                    } else if (serverHost.indexOf("saas") > -1) {
                        link.href = this.saasImg;
                    } else {
                        link.href = this.__ftpPath + nval.logoFile.filePath;
                    }
                    document.getElementsByTagName("head")[0].appendChild(link);
                }
            }
        },

        mounted() {

            if (this.$route.params && this.$route.params.searchValue != null) {
                this.searchValue = this.$route.params.searchValue;
            }
            this.getSaasIndex('zbrl', 0)
            aEntityAPI.saasRequest("UserProductSubscribeService", "saasIndexConfig",{platform: 7})
                .then((res) => {
                    if (res.data.success && res.data.data.user != null) {
                        this.isLogin = true
                    }
                });
        },

        methods: {

            mouseOver() {
                this.titleOrRemarkColor = true;
            },

            goActive(index) {
                this.topIndex = 2;
                if (index == 0) {
                    this.$router.push({
                        name: "saasActive", params: {
                            index: index
                        }
                    });
                }
                if (index == 1) {
                    this.$router.push({
                        name: "saasActive", params: {
                            index: index
                        }
                    });
                }
                if (index == 2) {
                    this.$router.push({
                        name: "saasActive", params: {
                            index: index
                        }
                    });
                }
            },

            gotoSubscribeAction(proItem) {
                var url = null;
                if (this.isLogin) {
                    url = window.location.protocol + "//" + window.location.host + "/common.html#/saasSubscribeTab/" + proItem.spId + "/" + proItem.spCode;
                    window.open(url)
                } else {
                    sessionStorage.setItem("beforeLoginUrl", "/common.html#/saasSubscribeTab/" + proItem.spId + "/" + proItem.spCode);
                    window.location.href = 'login.html#/login/hrsaas'
                    // url = window.location.protocol + "//" + window.location.host + "/login.html#/login/saas?fromUrl=" + encodeURIComponent(window.location.href);
                    // window.location.href = url
                }
            },


            //点击了产品分类
            meunListTabClick(itemSel) {
                this.showExpect = itemSel.code != 'wlhy,tms' ? true : false;
                this.meunList.forEach((item) => {
                    if (item.code == itemSel.code) {
                        item.select = true;
                    } else {
                        item.select = false;
                    }
                });
                this.selTenProTag = null;
                //重新获取产品详情的数据
                this.getSaasIndex(itemSel.code, 1);
            },

            //点击了对应的产品
            prductClick(item) {
                var url = window.location.protocol + "//" + window.location.host + "/hrsaas.html#/hrsaasDetail/" + item.spId + "?spId=" + item.spId + "&code=" + item.spCode;
                window.open(url);
            },

            //点击了单独的产品的类型
            proTabClick(tentItem, typeItem) {
                var that = this;
                that.tentProList.forEach((tItem) => {
                    if (tentItem.typeList != null) {
                        tentItem.typeList.forEach((type) => {
                            if (type.value == typeItem.value) {
                                type.select = true;
                                if (typeItem.value == null || typeItem.value == "") {
                                    that.selTenProTag = null;
                                } else {
                                    that.selTenProTag = tentItem.tentInfo.name + typeItem.name;
                                }
                            } else {
                                type.select = false;
                            }
                        });
                    }
                });
            },

            //获取产品对应的图片
            getProImg(item) {

                item.proBgImg = this.productCodeImgList[0].img;
                item.tagImg = "";
                item.tagList = [];
                if (
                    (item.spName != null && item.spName != "") ||
                    (item.spCode != null && item.spCode != "")
                ) {
                    this.productCodeImgList.forEach((temp) => {
                        if (
                            (temp.code != null && temp.code.indexOf(item.spCode) != -1) ||
                            temp.name == item.spName
                        ) {
                            item.proBgImg = temp.img;
                            item.tagImg = temp.tagImg;
                            item.tagList =
                                temp.proFeature != null && temp.proFeature.length > 0
                                    ? temp.proFeature
                                    : [];
                        }
                    });
                }
                return item;
            },

            //获取saas首页的数
            getSaasIndex(productCode, loadTag) {
                var params = {};
                if (productCode != null && productCode != "") {
                    params.productCode = productCode;
                }
                if (this.searchValue != null && this.searchValue != "") {
                    params.searchValue = this.searchValue;
                    // document.getElementById("product_saas_my").scrollIntoView(true);
                }
                aEntityAPI
                    .saasRequest("UserProductSubscribeService", "getSaasIndex", params)
                    .then((res) => {
                        this.grid_loading = false;
                        if (res.data.success) {
                            var tempDict = res.data.data;
                            var tentProList = tempDict.tentProList;
                            if (tentProList != null && tentProList.length > 0) {
                                tentProList.forEach((tentItem) => {
                                    var productList = tentItem.productList;
                                    tentItem.typeList = JSON.parse(JSON.stringify(this.typeList));
                                    productList.forEach((itemT) => {
                                        if (itemT.items != null && itemT.items.length > 0) {
                                            itemT.items.forEach((item) => {
                                                item = this.getProImg(item);
                                                item.leftInfo = item.spsStatusName;
                                                item.rightInfo = "";
                                                if (item.spsStatusValue == 7) {
                                                    item.leftInfo = "体验版";
                                                    if (item.endNowNum >= 0) {
                                                        //
                                                        item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.endNowNum + '</span>天</span>';
                                                    } else {
                                                        item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.endNowNum) + '</span>天</span>';
                                                    }
                                                }
                                                if (item.spsStatusValue == 5 && item.subMonths != null) {
                                                    if (item.subMonths >= 0) {
                                                        item.rightInfo = '<span>剩余<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + item.subMonths + '</span>月</span>';
                                                    } else {
                                                        item.rightInfo = '<span>过期<span style="color:#ff8d1a;font-size:18px;margin:0px 5px">' + (-item.subMonths) + '</span>月</span>';
                                                    }

                                                }
                                                if (item.spsStatusValue == null) {
                                                    item.leftInfo = "未订阅";
                                                }
                                            });
                                        }
                                    });
                                });

                                this.tentProList = tentProList;
                            } else {
                                this.tentProList = [];
                            }
                        } else {
                            this.$message({
                                showClose: true,
                                message: res.data.msg ? res.data.msg : "查询产品订阅数据失败",
                                type: "warning",
                            });
                        }
                    })
                    .catch((res) => {
                        this.grid_loading = false;
                    });
            },
        },

        filters: {
            //文字数超出时，超出部分使用...
            ellipsisTitle(value) {
                if (!value) return ''
                if (value.length > 15) {
                    return value.slice(0, 15) + '...'
                }
                return value
            },

            ellipsisRemark(value) {
                if (!value) return ''
                if (value.length > 28) {
                    return value.slice(0, 28) + '...'
                }
                return value
            }
        }
    };
</script>


<style>
    .hr_saas_index_body {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .hr_saas_index_body .home_banner_bg {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .hr_saas_index_body .home_banner_bg_imgA {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
    }

    .hr_saas_index_body .home_banner_bg_imgB {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        mix-blend-mode: screen;
        z-index: 4;
        /* object-fit: contain; */
    }

    .hr_saas_index_body .home_banner_bg_imgC {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        object-fit: contain;
    }

    .hr_saas_index_body .title_banner {
        /*padding-top: 55px;*/
        /*height: 580px;*/
        background-color: #ffffff;
    }

    .hr_saas_index_body .discount {
        height: 250px;
        background-color: #0f68e8;
    }

    .hr_saas_index_body .product {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .hr_saas_index_body .product_tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        width: 100%;
    }

    .hr_saas_index_body .product_tab_title {
        font-size: 35px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #393939;
        line-height: 50px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 50px;
    }

    .hr_saas_index_body .product_tab_body {
        width: 50%;
        /*padding: 45px;*/
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: 40px;
    }

    .hr_saas_index_body .product_tab_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hr_saas_index_body .product_tab_item .menumTive {
        border-bottom: 3px #0f68e8 solid;
    }

    .hr_saas_index_body .product_tab_item_icon {
        width: 76px;
        height: 83px;
    }

    .product_tab_item:hover {
        cursor: pointer;
    }

    .hr_saas_index_body .product_tab_item_lab {
        font-size: 18px;
        padding-bottom: 15px;
        padding-top: 35px;
        text-align: center;
        width: 100%;
    }

    .hr_saas_index_body .product_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        padding-bottom: 60px;
        background-color: #f5f7faff;
    }

    .hr_saas_index_body .product_body_empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 260px;
        width: 100%;
        background-color: #f5f7faff;
    }

    .hr_saas_index_body .tenant_pro_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-top: 30px;
    }

    .hr_saas_index_body .tenant_pro_title {
        font-size: 32px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #000000;
        margin-right: 33px;
    }

    .hr_saas_index_body .tenant_pro_menum {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .hr_saas_index_body .tenant_pro_type_item {
        font-size: 24px !important;
        font-family: Source Han Sans SC !important;
        font-weight: 500 !important;
        color: #333333 !important;
        background-color: #ffffff !important;
        text-align: center !important;
        margin-left: 50px !important;
        padding: 10px 40px !important;
        box-shadow: 0px 2px 10px #1571b342 !important;
    }

    .hr_saas_index_body .tenant_pro_type_item_sel {
        color: #ffffff !important;
        background-color: #2468f2ff !important;
    }

    .hr_saas_index_body .tenant_pro_type_item:hover {
        color: #ffffff !important;
        background-color: #2468f2ff !important;
    }

    .hr_saas_index_body .tenant_pro_type_item_sel {
        color: #e5e5e5;
        background-color: #808080;
    }

    .hr_saas_index_body .tenant_pro_cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .hr_saas_index_body .tenant_pro_cell_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .hr_saas_index_body .tenant_pro_cell_item_title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .hr_saas_index_body .tenant_pro_cell_item_title_line {
        width: 50px;
        height: 1px;
        background-color: #999999;
    }

    .hr_saas_index_body .tenant_pro_cell_item_title_lab {
        color: #505050;
        font-size: 18px;
        text-align: center;
        margin-left: 30px;
        margin-right: 30px;
        font-weight: 400;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 1266px;
        padding-left: 30px;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        width: 386px;
        height: 225px;
        margin-bottom: 30px;
        margin-right: 30px;
        background-color: #F5F7FA;
        border: 2px solid #FFFFFF;
        box-shadow: 2px 2px 15px #1571B342;
        border-radius: 5px;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_cont {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        margin: -25px 5% 3% 5%;
        width: 90%;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_title {
        color: #000000;
        position: absolute;
        width: 97%;
        left: 10px;
        top: 0;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_name {
        font-size: 21px;
        color: #303030;
        font-weight: 600;
        float: left;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_price {
        color: #000000;
        float: right;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_tag {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_tag_item {
        border: 1px #E99450 solid;
        color: #E99450;
        padding: 3px 15px;
        margin-right: 5px;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_remark {
        color: #505050;
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        height: 50px;
        word-break: break-all;
        overflow: hidden;
        margin-top: 32px;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_info {
        color: #505050;
        margin-left: 12px;
        margin-right: 20px;
        width: inherit;
        font-size: 14px;
        height: 40px;
        opacity: 1;
        overflow: hidden;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        transition: 0.3s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_info_nore {
        color: #505050;
        margin-left: 12px;
        margin-right: 20px;
        width: inherit;
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        opacity: 1;
        overflow: hidden;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

    }

    .hr_saas_index_body .tenant_hove_show {

        position: relative;
        color: #fff;
        font-size: 16px;
        height: 0px;
        bottom: 0;
        width: 100%;
        line-height: 38px;
        overflow: hidden;
        text-align: center;
        opacity: 0;
        transition: 0.3s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
    }

    .hr_saas_index_body .tenant_hove_show_subscription {

        float: left;
        color: #ffffff;
        background-color: #E99450;
        font-size: 16px;
        width: 45%;
        margin-left: 10px;
    }

    .hr_saas_index_body .tenant_hove_show_subscription_detail {

        float: right;
        color: #E99450;
        background-color: #FFFFFF;
        font-size: 16px;
        width: 45%;
        height: 36px;
        border: 1px solid #E99450;
        margin-right: 10px;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item:hover {
        box-shadow: 0px 0px 5px #E99450;
    }

    .hr_saas_index_body
    .tenant_pro_cell_item_body_item:hover
    .tenant_pro_cell_item_body_item_info {
        height: 0px;
        opacity: 0;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item:hover .tenant_hove_show {
        height: 40px;
        opacity: 1;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item:hover .tenant_pro_cell_item_body_item_saleTag_img {
        display: none;
    }

    .hr_saas_index_body .title_banner_body {
        width: 100%;
        height: 100%;
    }

    .hr_saas_index_body .discount_body {
        width: 100%;
        position: absolute;
        top: 490px;
        left: 0px;
        background-color: #ffffff88;
        /*background-color: #F5F7FA;*/
        /*opacity: 0.66;*/
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 110px;
        z-index: 12;

    }

    .hr_saas_index_body .discount_body_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        z-index: 11;
        height: 100%;
    }

    .hr_saas_index_body .discount_body_item:hover {
        background-color: #ffffff88;
        /*opacity: 1;*/
    }

    .hr_saas_index_body .discount_body_item:hover .discount_body_item_top_title {
        color: #2468f2;
    }

    .hr_saas_index_body .discount_body_item_top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .hr_saas_index_body .discount_body_item_top_title {
        color: #000;
        font-size: 24px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        margin-right: 5px;
    }

    .hr_saas_index_body .discount_body_item_bottom {
        color: #666666;
        font-size: 16px;
        width: 85%;
        height: auto;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
        word-wrap: break-word;
        overflow: hidden;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_saleTag {
        width: 50px;
        height: 50px;
        position: relative;
        top: 79%;
        left: 87%;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_top_info {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        height: 140px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_top_proImg {
        width: 100px;
        height: 100px;
        margin-top: 30px;
    }

    .hr_saas_index_body .cust_case {
        background: #fff;
        padding-top: 60px;
        padding-bottom: 60px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .hr_saas_index_body .cust_title {
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        line-height: 30px;
        margin-bottom: 30px;
    }

    .hr_saas_index_body .cust_body {
        width: 1200px;
        height: 360px;
    }

    .hr_saas_index_body .img_array_body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        color: #0f68e8;
        height: 100%;
        width: 1100px;
        margin-left: 50px;
    }

    .hr_saas_index_body .img_array_body_item {
        width: 230px;
        height: 90px;
        -o-object-fit: scale-down;
        object-fit: scale-down;
        border: 1px solid #ddd;
        padding: 20px 10px;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_top:hover {
        cursor: pointer;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_name:hover {
        color: #E99450;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item_remark:hover {
        color: #E99450;
    }

    .hr_saas_index_body .tenant_hove_show:hover {
        cursor: pointer;
    }

    .discount_body_item:hover {
        cursor: pointer;
    }

    .hr_saas_index_body .tenant_pro_cell_item_body_item:hover {
        cursor: pointer;
    }
</style>