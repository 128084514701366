<template>
  <div class="hr_saas_service_provide">
    <div class="hr_saas_service_provide_top"></div>
    <div class="hr_saas_service_provide_title">
        <div class="hr_saas_service_provide_title_info">
            <div class="hr_saas_service_provide_title_info_left">
                <img class="hr_saas_service_provide_title_info_img" :src="logo"/>
                <div class="hr_saas_service_provide_title_info_type">{{ employer.name }}</div>
            </div>
            <div v-if="employer.serviceType" class="hr_saas_service_provide_title_info_status">
                {{ employer.serviceType?employer.serviceType.name : '' }}
            </div>
            <div  class="hr_saas_service_provide_title_info_mobile">
                <span>联系方式：{{ employer.mobile }}</span>
                <span style="margin-left:20px;">创建时间：{{ employer.createTime }}</span>
            </div>
        </div>
    </div>
    <div class="hr_saas_service_provide_body">
        <div style="background-color: #f1f2f3;padding-top:100px;padding-bottom:20px;">
            <div class="hr_saas_service_provide_body_about" style="">
                <div class="hr_saas_service_provide_body_about_remark">
                    <div v-html="employer.introduce" class="hr_saas_service_provide_body_about_remark_content">
                    </div>
                </div>
                <img :src="recommend" class="hr_saas_service_provide_body_about_img" />
             </div>
        </div>

        <!-- <div class="hr_saas_service_provide_body_scope">
            <div class="hr_saas_service_provide_body_scope_title">
                <div class="hr_saas_service_provide_body_scope_title_tip">
                    服务范围
                </div>
                <div class="hr_saas_service_provide_body_scope_title_line">
                </div>
            </div>
            <div class="hr_saas_service_provide_body_scope_list">
                <div style="width:100%;display:flex;align-items:center;justify-content: flex-start;flex-wrap:wrap">
                   
                    <div v-html="business" class="hr_saas_service_provide_body_scope_list_temp">
                      
                    </div>
                </div>
            </div>
        </div> -->

        <div class="hr_saas_service_provide_body_scope">
            <div class="hr_saas_service_provide_body_scope_title">
                <div class="hr_saas_service_provide_body_scope_title_tip">
                    服务范围
                </div>
                <div class="hr_saas_service_provide_body_scope_title_line">
                </div>
            </div>
            <div class="hr_saas_service_provide_body_scope_list">
                <div style="width:1200px;display:flex;align-items:center;justify-content: flex-start;flex-wrap:wrap;margin:0 auto;">
                    <template v-for="(item,index) in businessList">
                    <div class="hr_saas_service_provide_body_scope_list_temp" :key="index">
                        <div class="hr_saas_service_provide_body_scope_list_item" >
                        <!-- <img :src="item.imgUrl" class="hr_saas_service_provide_body_scope_list_item_img" /> -->
                        <div class="hr_saas_service_provide_body_scope_list_item_title">
                            {{ item.title }}
                        </div>
                        <div class="hr_saas_service_provide_body_scope_list_item_remark">
                            {{ item.content }}
                        </div>
                    </div>
                    </div>
                </template>
                </div>
            </div>
        </div>

        <div class="hr_saas_service_provide_body_culture">
            <div class="hr_saas_service_provide_body_culture_body">
                <div class="hr_saas_service_provide_body_culture_body_title">
                    <div class="hr_saas_service_provide_body_culture_body_title_tip">
                        公司文化
                    </div>
                    <div class="hr_saas_service_provide_body_culture_body_title_line">
                    </div>
                </div>
                <div class="hr_saas_service_provide_body_culture_body_detail">
                    <el-carousel indicator-position="none" :autoplay="false" height="680px">
                        <el-carousel-item v-for="item in culture" :key="item.id">
                            
                            <img v-if="!item.flag" :src="item.file.filePath" class="hr_saas_service_provide_body_culture_body_detail_img" />
                            <!-- <div v-else class="hr_saas_service_provide_body_culture_body_detail_video">
                                <video-player class="video-player vjs-custom-skin vjs-big-play-centered"  ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                            </div> -->
                            <video v-else controls autoplay name="media" style="width:100%;height:100%;">
                                <source :src="item.file.filePath" type="audio/mpeg">
                            </video>
                        </el-carousel-item>
                    </el-carousel>
                    <!-- <div class="hr_saas_service_provide_body_culture_body_detail_video">
                        <video-player class="video-player vjs-custom-skin vjs-big-play-centered"  ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                    </div> -->
                </div>
            </div>
        </div>


        <!-- <div class="hr_saas_service_provide_body_scope">
            <div class="hr_saas_service_provide_body_scope_title">
                <div class="hr_saas_service_provide_body_scope_title_tip">
                    公司业务
                </div>
                <div class="hr_saas_service_provide_body_scope_title_line">
                </div>
            </div>
            <div class="hr_saas_service_provide_body_scope_list">
                <div style="width:100%;display:flex;align-items:center;justify-content: flex-start;flex-wrap:wrap">
                   
                    <div v-html="employer.operation" class="hr_saas_service_provide_body_scope_list_temp">
                      
                    </div>
                </div>
            </div>
        </div> -->

        <div class="hr_saas_service_provide_body_business">
            <div class="hr_saas_service_provide_body_scope_title">
                <div class="hr_saas_service_provide_body_scope_title_tip">
                    公司业务
                </div>
                <div class="hr_saas_service_provide_body_business_title_line">
                </div>
            </div>
            <div class="hr_saas_service_provide_body_business_list">
                <div style="width:1200px;display:flex;align-items:center;justify-content: flex-start;flex-wrap:wrap;margin:0 auto;">
                    <template v-for="(item,index) in operationList">
                    <div class="hr_saas_service_provide_body_business_list_temp" :key="index">
                        <div class="hr_saas_service_provide_body_business_list_item" >
                            <div class="hr_saas_service_provide_body_business_list_item_title">
                                {{ item.title }}
                            </div>
                            <div class="hr_saas_service_provide_body_business_list_item_remark">
                               {{ item.content }}
                            </div>
                    </div>
                    </div>
                </template>
                </div>
            </div>
        </div>

        <div class="hr_saas_service_provide_body_culture">
            <div class="hr_saas_service_provide_body_culture_body">
                <div class="hr_saas_service_provide_body_culture_body_title">
                    <div class="hr_saas_service_provide_body_culture_body_title_tip">
                        合作伙伴
                    </div>
                    <div class="hr_saas_service_provide_body_culture_body_title_line">
                    </div>
                </div>
                <div class="hr_saas_service_provide_body_culture_body_detail">
                    <div class="hr_saas_service_provide_body_culture_body_detail_scroll" >
                        <vue-seamless-scroll
                        :data="parter"
                        :class-option="defaultOption"
                        class="hr_saas_service_provide_body_culture_body_detail_scroll_creditCarousel"
                        >
                        <ul class="ul-item">
                            <li
                            class="li-item"
                            v-for="(item, index) in parter" :key="index" :data-id="index"
                            >
                            <div class="credit_carousel_item_img">
                                <div class="seamless_item_cell" v-for="(img, indexImg) in item" :key="indexImg">
                                    <img :src="img.file.filePath" alt="" class="seamless_item_cell_img">
                                </div>
                                
                            </div>
                            
                            </li>
                        </ul>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>


import vueSeamlessScroll from "vue-seamless-scroll";
import aEntityAPI from "@/api/sysAPI.js";
import { videoPlayer } from 'vue-video-player'
import baseAPI from "@/project/zbrl/api/baseAPI.js";
import 'video.js/dist/video-js.css'


export default {

    components: {
        vueSeamlessScroll,
        videoPlayer
    },

    data () {
        return {
            curId:'',
            introduce:null,
            business:null,
            employer:{},
            company:{},
            culture:[],
            parter:[],
            recommend:'',
            logo:'',
            businessList: [
                {
                    title: "",
                    content: "",
                }
            ],
            operationList: [
                {
                    title: "",
                    content: "",
                }
            ],
            lunboList:[
            {
                imgUrl: require('@/assets/hrsaas/detail/hr_service_company_1.jpg'),
                title:'人才招聘',
                id:1001,
                remark:'用工合规、类型丰富、资源共享',
            },{
                imgUrl: require('@/assets/hrsaas/detail/hr_service_company_2.jpg'),
                title:'培训发展',
                id:1002,
                remark:'提供多样化的培训课程',
            },{
                imgUrl: require('@/assets/hrsaas/detail/hr_service_company_3.jpg'),
                title:'人才管理',
                id:1003,
                remark:'供完善的人才管理方案',
            },{
                title:'人才管理',
                id:1004,
                remark:'供完善的人才管理方案',
            }],
            parter: [],
            partnerList:[],
           
            imgList:[
            {
                imgUrl: require('@/assets/hrsaas/index/fuli_act.png'),
            },{
                imgUrl: require('@/assets/hrsaas/index/jixiao_act.png'),
            },{
                imgUrl: require('@/assets/hrsaas/index/fazhang_act.png'),
            },{
                imgUrl: require('@/assets/hrsaas/index/guanli_act.png'),
            },{
                imgUrl: require('@/assets/hrsaas/index/fuwu_act.png'),
            },{
                imgUrl: require('@/assets/hrsaas/index/zhaopin_act.png'),
            },],
            ceshiImg:require('@/assets/hrsaas/index/ceshi.png'),
            companyTag: require("@/assets/hrsaas/index/company_tag.png"),
            htmlContent:'<div>1.人才招聘：我们拥有专业的招聘团队，为客户提供高效、精准的招聘服务，帮助企业快速找到符合岗位需求的人才。</div><div>2.培训发展：我们提供多样化的培训课程，涵盖领导力培训、技能提升、团队建设等方面，帮助企业员工不断提升自我，增强竞争力。</div><div>3.人才管理：我们为客户提供完善的人才管理方案，包括绩效评估、员工福利、人才流动管理等，帮助企业建立健康、稳定的人才管理机制。</div>',
            serviceProviderBg:require("@/assets/hrsaas/index/service_provider_bg.jpg"),
            defaultOption: {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            },
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4", // 类型
                    src: require("@/assets/ygx/ygx_logo.mp4"), // url地址
                }],
                poster: require("@/assets/ygx/banner.png"), // 封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            },

        }
    },
    computed: {
        player() {
            return this.$ref.videoPlayer.player
        }
    },
    methods:{
        getHomeDataInfo() {
            let that = this;
            aEntityAPI
                .request("HomePageConfigService", "getHomePageDataInfo")
                .then((res) => {
                if (res.data.success) {
                    var dataDict = res.data.data;
                
                    if (
                    dataDict.partnerList != null &&
                    dataDict.partnerList.length > 0
                    ) {
                        var dataList = dataDict.partnerList.slice(0,1);
                        that.partnerList = [];
                        if (dataList.length > 2) {
                            var tempList = [];
                            for (let index = 0; index < dataList.length; index++) {
                            const element = dataList[index];
                            tempList.push(element);
                            if (index % 2 == 1) {
                                that.partnerList.push(tempList);
                                tempList = [];
                            }else{
                                if(index == dataList.length -1){
                                that.partnerList.push(tempList);
                                }
                            }
                            }
                        } else {
                            that.partnerList.push(dataList);
                        }
                    }
                    console.log(that.partnerList);
                }
            });
        },
        getCompanyDetail(){
            aEntityAPI.request("ZbrlApiService", "getCompanyDetailById", {id:this.curId}).then(res => {
                    if (res.data.success) {
                        this.provideDetail = res.data.data;
                        if(res.data.data?.company){
                            this.company = res.data.data?.company
                        }
                        if(res.data.data?.employer){
                            this.employer = res.data.data?.employer
                        }
                        if(res.data.data?.employer?.recommendFile){
                            this.recommend = this.__ftpPath + res.data.data?.employer?.recommendFile.filePath;
                        }
                        if(res.data.data?.webList){
                            var data = res.data.data?.webList
                            if (data && data.营业范围) {
                                var businessList = [];
                                data.营业范围.forEach(o => {
                                    let d = {
                                        title: o.title,
                                        content: o.content
                                    }
                                    businessList.push(d)
                                }) 
                                this.businessList = businessList
                            }
                            if (data && data.公司业务) {
                                var operationList = [];
                                data.公司业务.forEach(o => {
                                    let d = {
                                        title: o.title,
                                        content: o.content
                                    }
                                    operationList.push(d)
                                })
                                this.operationList = operationList
                            }
                        }
                        console.log(this.businessList,this.operationList,'this.operationListthis.operationList')
                        if(res.data.data?.parter){
                            let dataList = res.data.data?.parter
                            dataList.forEach(item=>{
                                item.file.filePath = this.__ftpPath + item.file.filePath
                            })

                            console.log(this.parter,'this.parter')
                            this.parter = [];
                            if (dataList.length > 2) {
                                var tempList = [];
                                for (let index = 0; index < dataList.length; index++) {
                                const element = dataList[index];
                                tempList.push(element);
                                if (index % 2 == 1) {
                                    this.parter.push(tempList);
                                    tempList = [];
                                }else{
                                    if(index == dataList.length -1){
                                        this.parter.push(tempList);
                                    }
                                }
                                }
                            } else {
                                this.parter.push(dataList);
                            }

                            
                            console.log(this.parter,'this.parterthis.parter')
                        }
                        if(res.data.data?.culture){
                            res.data.data?.culture.forEach(item=>{
                                item.file.filePath = this.__ftpPath + item.file.filePath
                            })
                            this.$set(this,'culture',res.data.data?.culture)
                            this.culture.forEach(item=>{
                                console.log(typeof(item.file.fileName),'item.file.fileName')
                                let str = item.file.fileName
                               if( str.indexOf('mp4') > -1){
                                item.flag = true
                               }
                            })
                        }
                        if(res.data.data?.employer?.logoFile){
                            this.logo = this.__ftpPath + res.data.data?.employer?.logoFile.filePath;
                        }
                        if(res.data.data?.employer?.introduce){
                            this.employer.introduce = res.data.data?.employer?.introduce.replaceAll(
                                "<div>",
                                "<div style='color:#fff;line-height: 32px;font-size:18px;'>"
                            );
                            this.employer.introduce = res.data.data?.employer?.introduce.replaceAll(
                                "<p>",
                                "<p style='color:#fff;line-height: 32px;font-size:18px;'>"
                            );
                        }
                        if(res.data.data?.employer?.business){
                            this.business = res.data.data?.employer?.business
                        }
                       console.log(data,'datadatadata')
                    } else {
                        this.$message.error('错误，失败');
                    }
                })
        },
        // 播放回调
        onPlayerPlay(player) {
            console.log('player play!', player)
            this.$refs.videoPlayer.player.play() // 播放
        },

        // 暂停回调
        onPlayerPause(player) {
            this.$refs.videoPlayer.player.pause() // 暂停
        },

        // 视频播完回调
        onPlayerEnded($event) {
            console.log(player)
            this.$refs.videoPlayer.player.src(src) // 重置进度条复制代码
        },
        changeHtml(){
            this.htmlContent = this.htmlContent.replaceAll(
                "<div>",
                "<div style='color:#fff;line-height: 32px;font-size:18px;'>"
            );
        },
    },
    mounted () {
        console.log(this.curId,'this.curId')
        this.getHomeDataInfo();
        this.getCompanyDetail();
        this.changeHtml();
    },
    created () {
        if (this.$route.params && this.$route.params.spId != null) {
            this.curId = this.$route.params.spId;
        }
    }
}
</script>

<style lang="less" scoped>
    .hr_saas_service_provide{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 55px;
    }

    .hr_saas_service_provide_top{
        width: 100%;
        height: 604px;
        background-image: url("../assets/hrsaas/index/service_provider_bg.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: -55px;
    }
    .hr_saas_service_provide_title{
        width: 100%;
        height: 110px;
        box-shadow: 0px 2px 43px 0px rgba(21,113,179,0.07);
        margin-top: -110px;
        background: rgba(255, 255, 255, 0.5);;
        color: #1571B3;
        display: flex;
        align-items: center;

    }
    .hr_saas_service_provide_title_info{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 90%;
        margin: 0 auto;

    }
    .hr_saas_service_provide_title_info_img{
        width: 55px;
        height: 55px;
        margin-right: 20px;
    }
    .hr_saas_service_provide_title_info_type{
        
        font-size: 36px;
        color: #fff;
        border-radius: 7px;
    }
    .hr_saas_service_provide_title_info_status{
        padding: 5px 15px;
        color: #fff;
        background: #FBCD57;
        font-size: 22px;
        border-radius: 7px;

    }
    .hr_saas_service_provide_title_info_mobile{
        margin-left: 20px;
        color: #fff;
        font-size: 22px;
    }

    .hr_saas_service_provide_content{
        width: 80%;
        min-width: 1000px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .hr_saas_service_provide_content_title{
        font-size: 30px;
    }
    .hr_saas_service_provide_content_detail{
        display: flex;
        flex-direction: column;
        align-items: left;
    }
    .hr_saas_service_provide_footer{
        width: 80%;
        min-width: 1000px;
        margin: 0 10%;
    }
    .hr_saas_service_provide_footer_title{
        font-size: 30px;
        text-align: center;
        align-items: center;
    }
    .hr_saas_service_provide_footer_body{
        width: 100%;
        
    }
    .saas_detail_body_product_right_img{
        width: 230px;
        height: 90px;
        /* margin-top: 10px; */
    }

    .hr_saas_service_provide_body{
        width:100%;
    }

    .hr_saas_service_provide_body_about{
        width: 1200px;
        margin: 0 auto;
        text-align: right;
        display: flex;
        margin-bottom: 105px;
    }

    .hr_saas_service_provide_body_about_remark{
        margin-top: 54px;
        background: rgba(78, 126, 146, 1);
        width: 617px;
        height: 516px;
        position: absolute;
        overflow: hidden;
    }
    .hr_saas_service_provide_body_about_remark_content{
        padding: 34px 27px;
        text-align: left;
        color: #fff;
    }
    .hr_saas_service_provide_body_about_img{
        width: 771px;
        height: 505px;
        margin-left: 410px;
    }

    .hr_saas_service_provide_body_culture{
        width: 100%;
        background: #f1f2f3;
        padding-bottom: 120px;
    }
    .hr_saas_service_provide_body_culture_body{
        width: 90%;
        margin: 0 auto;
    }
    .hr_saas_service_provide_body_culture_body_title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
        padding-top: 100px;;
    }
    .hr_saas_service_provide_body_culture_body_title_tip{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
    }
    .hr_saas_service_provide_body_culture_body_title_line{
        width: 60px;
        height: 3px;
        background: #333333;
        margin-top: 5px;
    }

    .hr_saas_service_provide_body_business{
        min-width: 1440px;
        width: 90%;
        margin: 0 auto;
    }

    .hr_saas_service_provide_body_business_title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
    .hr_saas_service_provide_body_business_title_tip{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
    }
    .hr_saas_service_provide_body_business_title_line{
        width: 60px;
        height: 3px;
        background: #333333;
        margin-top: 5px;
    }


    .hr_saas_service_provide_body_culture_body_detail_scroll {
        margin-top: 5vh;
        width: 1200px;
    }

    .hr_saas_service_provide_body_culture_body_detail_scroll_creditCarousel {
        width: 1200px;
        height: 350px;
        margin: 0 auto;
        overflow: hidden;
    }

//  .hp_partner ul {
//   list-style: none;
//   padding: 0;
//   margin: 0 auto;
// }

    .ul-item {
        display: flex;
    }

    .li-item {
        width: 400px;
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .credit_carousel_item_img{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    /* background-color: #7ca1d2; */
    }
    .seamless_item_cell {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    }
    .seamless_item_cell_img {
    width: 80%;
    max-height: 80%;
    }

    .hr_saas_service_provide_body_culture_body_detail{
        width: 1200px;
        margin: 0 auto;
        background: #fff;
        margin-top:35px;
    }
    .hr_saas_service_provide_body_culture_body_detail_video{
        width: 1200px;
        height: 618px;
    }
    .hr_saas_service_provide_body_culture_body_detail_img{
        width: 100%;
        height: 100%;
    }
    .hr_saas_service_provide_title_info_left{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }



    .hr_saas_service_provide_body_scope{
        min-width: 1200px;
        width: 80%;
        margin: 0 auto;
    }
    .hr_saas_service_provide_body_scope_title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
    .hr_saas_service_provide_body_scope_title_tip{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
    }
    .hr_saas_service_provide_body_scope_title_line{
        width: 60px;
        height: 3px;
        background: #333333;
        margin-top: 5px;
    }
    .hr_saas_service_provide_body_scope_list{
        width: 100%;
    }
    .hr_saas_service_provide_body_scope_list_temp{
        width: 33%;
        display: flex;
        flex-direction: column;
        align-content: center;

    }
    .hr_saas_service_provide_body_scope_list_item{
        // width: 375px;
        // height: 220px;
        // margin: 0 auto;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        // margin-top: 18px;

        width: 375px;
        height: 220px;
        margin: 0 auto;
        
        text-align: center;
        margin-top: 18px;
        box-shadow: 0px 2px 70px 0px rgba(0,0,0,0.07);
        background: #FFFFFF;
        border-radius: 4px;

        &:hover{
            background: linear-gradient(45deg, #FED56C, #F0AD00);
            box-shadow: -2px 2px 0px 0px rgba(181,187,193,0.91);
            
            .hr_saas_service_provide_body_scope_list_item_title{
                width: 90%;
                height: 70px;
                color: #fff;
                margin: 0 auto;
                font-size: 26px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
            }
            .hr_saas_service_provide_body_scope_list_item_remark{
                font-size: 20px;
                color: #fff;
                width: 90%;
                margin: 0 auto;
                height: 130px;
                overflow: hidden;
                margin-bottom: 20px;
                
            }
        }
    }


    .hr_saas_service_provide_body_scope_list_item_title{
        color: #111111;
        font-size: 26px;
        width: 90%;
        height: 70px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    }
    .hr_saas_service_provide_body_scope_list_item_remark{
        font-size: 20px;
        color: #666666;
        width: 90%;
        height: 130px;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .hr_saas_service_provide_body_business{
        min-width: 1440px;
        width: 90%;
        margin: 0 auto;
    }

    .hr_saas_service_provide_body_business_title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
    .hr_saas_service_provide_body_business_title_tip{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
    }
    .hr_saas_service_provide_body_business_title_line{
        width: 60px;
        height: 3px;
        background: #333333;
        margin-top: 5px;
    }
    .hr_saas_service_provide_body_business_list{
        width: 100%;
        margin-top:20px;
    }
    .hr_saas_service_provide_body_business_list_temp{
        width: 33%;
        display: flex;
        flex-direction: column;
        align-content: center;

    }
    .hr_saas_service_provide_body_business_list_item{
        width: 375px;
        height: 220px;
        margin: 0 auto;
        
        text-align: center;
        margin-top: 18px;
        box-shadow: 0px 2px 70px 0px rgba(0,0,0,0.07);
        background: #FFFFFF;
        border-radius: 4px;

        &:hover{
            background: linear-gradient(45deg, #FED56C, #F0AD00);
            box-shadow: -2px 2px 0px 0px rgba(181,187,193,0.91);
            
            .hr_saas_service_provide_body_business_list_item_title{
                width: 90%;
                height: 70px;
                color: #fff;
                margin: 0 auto;
                font-size: 26px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
                white-space: nowrap !important;
            }
            .hr_saas_service_provide_body_business_list_item_remark{
                font-size: 20px;
                color: #fff;
                width: 90%;
                margin: 0 auto;
                height: 130px;
                overflow: hidden;
                margin-bottom: 20px;
                
            }
        }
    }

    .hr_saas_service_provide_body_business_list_item_title{
        width: 90%;
        height: 70px;
        color: #111111;
        margin: 0 auto;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
    }
    .hr_saas_service_provide_body_business_list_item_remark{
        width: 90%;
        height: 130px;
        margin: 0 auto;
        overflow: hidden;
        font-size: 20px;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

</style>