<template>
    <div class="saas_body">
        <div id="saas_header" class="saas_header">
            <div class="home_header_left">
                <div class="home-logo" @click="clickHomeMenum(1)">
                    <img src="../assets/hrsaas/index/hr_saas_logo.png" style="margin-top: 6px"/>
                </div>
                <span class="home-sys-name" @click="clickHomeMenum(1)">工众人力SAAS平台</span>
                <div class="home-menu">
                    <ul>
                        <li :class="{ active: topIndex==1}"><a @click="clickHomeMenum(1)">产品</a></li>
                        <li :class="{ active: topIndex==2}"><a @click="clickHomeMenum(2)">活动</a></li>
                    </ul>
                </div>
            </div>
            <div class="home_header_right">
                <div class="header-search">
                    <el-input placeholder="请输入产品的名称" suffix-icon="el-icon-search" class=""
                              v-model.trim="searchValue" @input="searchProduct"/>
                    <i class="aside-input-search"/>
                </div>
                <div class="home-btn home-hrsaas-btn" v-if="user != null && user.id != null">
                    <div class="home_logout_btn">
                        <el-button type="text" style="margin-right: 20px; font-weight: bold" @click="logoutAction()">
                            退出
                        </el-button>
                    </div>
                    <div class="home_root_btn">
                        <el-button type="text" style="font-weight: bold" @click="landInAction()">进入系统</el-button>
                    </div>
                </div>
                <div class="home-btn home-hrsaas-btn" v-else>
                    <div class="home_login_btn">
                        <el-button type="text" style="margin-right: 20px; font-weight: bold" @click="loginAction()">登录
                        </el-button>
                    </div>
                    <div class="home_reg_btn">
                        <el-button type="text" @click="regAction()">快速注册</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="saas_container">
            <router-view></router-view>
        </div>

        <div class="saas_footer">
            <div class="footer_center_info">
                <div class="home-ba-info" v-html="config.webLic"></div>
                <div class="home-ba-info">
                    邮箱：{{ config.email }} &nbsp; 电话：{{ config.phone }} &nbsp;<br/><br/>
                    地址：{{ config.addr }} &nbsp;
                </div>
            </div>
        </div>


        <div v-if="billList != null && billList.length > 0 " class="saas_buy_car" @click="gotoPayView()">
            <div class="saas_buy_car_title" style="margin-top: 20px;color: #1a1a1a;font-size: 18px">待支付</div>
            <div class="saas_buy_car_title " style="margin-right:3px;font-size:20px">
                <span class="price_color">{{ billList.length }}</span> 单
            </div>

            <img class="saas_buy_car_img" src="@/assets/saasImg/by_car.png"/>
        </div>


        <div class="saas_buy_car_qr_code">
            <div class="saas_buy_car_title_qr">
                <img :src="qr_code.img" alt="二维码" style="width: 151px;height: 145px;"/>
            </div>
            <div class="saas_buy_car_qr_code_title" style="">
                -联系人二维码-
            </div>
        </div>


    </div>
</template>


<script>
    import aEntityAPI from "@/api/sysAPI.js";
    import saasIndex from "./hrsaasIndex.vue";
    import {
        mapActions
    } from "vuex";

    export default {
        data() {
            return {
                tenantDomain: "",
                topIndex: 1,
                user: {},
                searchValue: null,
                logoUrl: "",
                config: {},
                hasUserTag: false,
                appList: [],
                billList: [], //待支付的账单
                activeHeader: "header",
                qr_code: {
                    img: require("@/assets/hrsaas/index/hrsaas_lijian.png"),
                },
                hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
                saasImg: require("@/assets/saas_logo.png"),
            };
        },

        created() {

        },

        watch: {
            $route(to, from) {

                if (to.path.indexOf("saasActive") > -1) {
                    this.topIndex = 2
                    if (this.$route.params && this.$route.params.index != null) {
                        setTimeout(() => {
                            this.toScrollTop(this.$route.params.index);
                        }, 50)
                    }
                } else {
                    this.topIndex = 1
                    setTimeout(() => {
                        this.toScrollTop(3);
                    }, 50)
                }

            },

            sysConfig: function (nval, oval) {
                if (nval && nval.logoFile && nval.logoFile.filePath) {
                    var link = document.createElement("link");
                    link.type = "image/x-icon";
                    link.rel = "shortcut icon";

                    var serverHost = window.location.host;
                    if (serverHost.indexOf("hrsaas") > -1 || serverHost.indexOf("saas.gz-vip.com") > -1) {
                        link.href = this.hrsaasImg;
                    } else if (serverHost.indexOf("saas") > -1) {
                        link.href = this.saasImg;
                    } else {
                        link.href = this.__ftpPath + nval.logoFile.filePath;
                    }
                    document.getElementsByTagName("head")[0].appendChild(link);
                }
            }
        },

        methods: {
            ...mapActions(["user/logout", "page/clearPage"]),

            clickHomeMenum(index) {

                this.topIndex = index;
                if (index == 1) {
                    document.title = '人力SaaS首页';
                    this.$router.push({name: "hrsaas"});
                }
                if (index == 2) {
                    document.title = '人力SaaS活动';
                    this.$router.push({
                        name: "saasActive",
                        params: {
                            index: 3
                        }
                    });
                }

            },

            toScrollTopIndex() {
                document.documentElement.scrollTop = 900;
            },

            toScrollTop(index) {
                if (index == 0) {
                    document.documentElement.scrollTop = 900;
                }
                if (index == 1) {
                    document.documentElement.scrollTop = 1400;
                }
                if (index == 2) {
                    document.documentElement.scrollTop = 1900;
                }
                if (index == 3) {
                    document.documentElement.scrollTop = 200;
                }
            },

            //获取产品的信息
            getProductConfig() {
                let that = this;
                aEntityAPI
                    .saasRequest("UserProductSubscribeService", "saasIndexConfig", {platform: 7})
                    .then((res) => {
                        this.grid_loading = false;

                        if (res.data.success) {
                            var ftpUrl = that.__ftpPath;
                            if (res.data.data.saasTenantBillList != null) {
                                this.billList = res.data.data.saasTenantBillList;
                            }
                            if (res.data.data.user != null) {
                                this.user = res.data.data.user;
                            }
                            var dataDict = res.data.data.companyConfig;
                            this.config = dataDict;
                            var appList = [];

                            if (dataDict.driverMiniApp != null) {
                                var appMin = {
                                    icon: ftpUrl + dataDict.driverMiniApp.filePath,
                                    name: "司机小程序",
                                };
                                appList.push(appMin);
                            }
                            if (dataDict.driverAndriodApp != null) {
                                var appMin = {
                                    icon: ftpUrl + dataDict.driverAndriodApp.filePath,
                                    name: "司机APP",
                                };
                                appList.push(appMin);
                            }
                            if (dataDict.shipperMiniApp != null) {
                                var appMin = {
                                    icon: ftpUrl + dataDict.shipperMiniApp.filePath,
                                    name: "货主小程序",
                                };
                                appList.push(appMin);
                            }
                            if (dataDict.shipperApp != null) {
                                var appMin = {
                                    icon: ftpUrl + dataDict.shipperApp.filePath,
                                    name: "货主APP",
                                };
                                appList.push(appMin);
                            }
                            if (dataDict.logoFile != null) {
                                this.logoUrl = ftpUrl + dataDict.logoFile.filePath;

                            }
                            this.appList = appList;

                            if (this.searchValue != null && this.searchValue != "") {
                                document.getElementById("product_saas_my").scrollIntoView(true);
                            }
                        }
                    })
                    .catch((res) => {
                        this.grid_loading = false;
                    });
            },

            //开始搜索
            searchProduct() {
                if (this.searchValue != null && this.searchValue != "") {
                    window.location.href = "hrsaas.html#/hrsaas/" + this.searchValue;
                } else {
                    window.location.href = "hrsaas.html#/hrsaas";
                }
                window.location.reload();
            },

            //前往支付界面
            gotoPayView() {
                var url =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/common.html#/saasProSubBillList";
                window.open(url);

            },

            logoutAction() {
                this["user/logout"]().then((data) => {
                    if (data) {
                        window.location.href = "/login.html";
                    }
                });
            },

            //注册
            regAction() {
                window.location.href = window.location.protocol + "//" + window.location.host + '/login.html#/regHrsaas' //0:物流saas注册 1:工众人力saas注册
            },

            //进入系统
            landInAction() {
                var url =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/login.html#/login";
                window.open(url);
            },

            //登录
            loginAction() {
                var url =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/login.html#/login/saas/saas.html";


                window.location.href = 'login.html#/login/hrsaas'

            },
        },

        mounted() {
            if (this.$route.params && this.$route.params.searchValue != null) {
                this.searchValue = this.$route.params.searchValue;
            }
            if (this.$route.params && this.$route.params.index != null) {

            }

            this.getProductConfig();
        },
    };
</script>
<style>

    body {
        overflow: auto;
    }

    .saas_body {
        height: 100%;
        background-color: #2d3a4b;
        min-width: 1200px;
    }

    .saas_container {
        display: flex;
        width: 100%;
        background-color: #fff;
        min-height: 500px;
    }

    .saas_buy_car {
        right: 10px;
        top: 45%;
        background: #fff;
        position: fixed;
        border: 2px solid #ffffff;

        width: 72px;
        height: 200px;
        background: #F5F7FA;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 2px 15px 0px rgba(21, 113, 179, 0.26);
        opacity: 0.97;
        border-radius: 36px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        z-index: 22;
    }

    .saas_buy_car_qr_code {
        right: 10px;
        top: 20%;
        background: #fff;
        position: fixed;
        border: 2px solid #ffffff;

        width: 173px;
        height: 190px;
        background: #F5F7FA;
        border: 2px solid #0050DA;
        box-shadow: 0px 2px 15px 0px rgba(21, 113, 179, 0.26);
        opacity: 0.97;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        z-index: 22;
    }

    .saas_buy_car:hover {
        cursor: pointer;

    }

    .saas_buy_car_title {
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #393939;
    }

    .price_color {
        color: #2468F2;
    }

    .saas_buy_car_img {
        width: 38px;
        height: 34px;
    }

    .saas_header {
        height: 55px;
        position: fixed;
        background-color: #8c939d66;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 22;
        background-image: url("../assets/hrsaas/header@2x.png");
    }

    .home_header_left {
        margin-left: 10%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .home_header_right {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .home-logo {
        width: 50px;
        display: inline-block;
        height: 50px;
        overflow: hidden;
    }

    .home-logo:hover {
        cursor: pointer;
    }

    .home-sys-name:hover {
        cursor: pointer;
    }

    .home-logo img {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
    }

    .home-sys-name {
        font-size: 20px;
        color: #ffffff;
        padding-left: 20px;
        padding-right: 20px;
        font-family: ZKGDH;
        font-size: 30px;
        width: 340px;
    }

    .home-menu {
        width: 300px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #000000;
        height: 100%;
        margin-left: 10%;
    }

    .home-hrsaas-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .home-menu:hover {
        cursor: pointer;

    }

    .home-menu ul > li {
        padding: 10px 20px;

    }

    .home-menu ul > li {
        padding: 10px 20px;

    }

    .home-menu ul > li {
        float: left;

        font-size: 16px;
        color: #ffffff;
    }

    .home-menu ul > li > a {
        color: #ffffff;
        text-decoration: none;
    }

    .home-menu ul .active {
        border-bottom: 2px solid #FFFFFF;
    }

    /* .home-menu ul > li.active > a {
      border-bottom: 2px solid #409eff;
    } */

    .home-btn {
        /*width: 150px;*/
        /*display: inline-block;*/
        height: 100%;
        line-height: 60px;
        /*padding: 20px 0;*/
    }

    .header-search {
        /*margin-right: 20px;*/
    }

    .saas_footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 160px;
        color: #fff;
        background-color: #000;
    }

    .footer_center_info {
        /* margin: 0 auto; */
    }

    .app_body_view {
        float: right;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 40px;
    }

    .app_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-left: 20px;
    }

    .home-ba-info {
        font-size: 16px;
        color: white;
        margin-top: 10px;
    }

    .app_name {
        font-size: 16px;
        color: white;
        margin-top: 10px;
    }

    .home_header_right .el-input--mini .el-input__inner {
        height: 55px;
        line-height: 55px;
        width: 166px;
        border: none;
    }

    .home_header_right .el-input--mini .el-icon-search {
        font-size: 18px;
        color: #E9AF22;
        margin-right: 10px;
    }

    .home_login_btn {
        height: 55px;
        width: 80px;
        background-color: #2B4760;
    }

    .home_login_btn .el-button {
        width: 80px;
        margin: auto;
        height: 55px;
        color: #ffffff;
    }

    .home_logout_btn {
        height: 55px;
        width: 80px;
        background-color: #2B4760;
    }

    .home_logout_btn .el-button {
        width: 80px;
        margin: auto;
        height: 55px;
        color: #ffffff;
    }

    .home_reg_btn {
        height: 55px;
        width: 120px;
        background-color: #E99450;
    }

    .home_reg_btn .el-button {
        width: 120px;
        margin: auto;
        height: 55px;
        color: #ffffff;
    }

    .home_root_btn {
        height: 55px;
        width: 120px;
        background-color: #E99450;
    }

    .home_root_btn .el-button {
        width: 120px;
        margin: auto;
        height: 55px;
        color: #ffffff;
    }

    .saas_buy_car_qr_code_title {
        color: #395EAB;
        font-size: 18px;
        margin-top: -10px;
    }

    @font-face {
        font-family: ZKGDH;
        src: url("../font/iconfont_zkgdh.TTF");
    }
</style>