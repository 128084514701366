import Vue from 'vue'
import Router from 'vue-router'

import saasIndex from './hrsaasIndex.vue'
import hrsaasDetail from './hrsaasDetail.vue'
import hrsaasActive from './hrsaasActive.vue'
import hrsaasServiceProvideDetail from "./hrsaasServiceProvideDetail.vue"

import saasSubscribeTab from "../project/common/views/saasSubscribe/saasSubscribeTab.vue"

Vue.use(Router)


export default new Router({
    routes: [

        {path: '*', redirect: '/hrsaas'},

        {//系统入口页面，
            path: '/hrsaas/:searchValue?',
            name: 'hrsaas',
            props: true,
            meta: {
                title: '人力SaaS首页'
            },
            component: saasIndex
        },

        {
            path: '/hrsaasDetail/:spId?',
            name: 'hrsaasDetail',
            meta: {
                title: '人力SaaS详情'
            },
            component: hrsaasDetail
        },
        {
            path: '/hrsaasServiceProvideDetail/:spId?',
            name: 'hrsaasServiceProvideDetail',
            meta: {
                title: '入驻服务商详情'
            },
            component: hrsaasServiceProvideDetail
        },
        {
            path: '/saasActive',
            name: 'saasActive',

            meta: {
                title: '活动详情'
            },
            component: hrsaasActive
        },

    ]
})
